import React from 'react'
import { useParams } from 'react-router-dom';
import MyEvent from "./MyEvent";

function GetMyEventId () {
    let { id } = useParams();

    return(
        <MyEvent participation={id}/>
    )
}

export default GetMyEventId;
