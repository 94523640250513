import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./CardEvent.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAnchor,
    faCalendarDay,
    faClock,
    faMapMarkerAlt,
    faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;

const BASKET = 0;
const VALIDATE = 1;
const WAITLIST = 2;
const WAITLIST_CONTACT = 3;


class CardEvent extends Component {


    render () {

        return (
            <div className={styles.card}>

                <div className={styles.titleContainer}>
                    {this.props.titre}
                </div>

                <div className={styles.contentContainer}>
                    <div className={styles.infosContainer}>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faCalendarDay}/>
                            </div>
                            <div className={styles.text}>
                                <b>{this.props.date}</b>
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon
                                    icon={
                                        this.props.type === SORTIE_MER ? faAnchor :
                                            this.props.type === SOIREE_THEME ? faUsers :
                                                this.props.type === CONFERENCE ? faMicrophone :
                                                    null
                                    }
                                />
                            </div>
                            <div className={styles.text}>
                                {this.props.type === SORTIE_MER ? "Sortie en mer" :
                                    this.props.type === SOIREE_THEME ? "Soirée à thème" :
                                        this.props.type === CONFERENCE ? "Conférence" :
                                            null
                                }
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            </div>
                            <div className={styles.text}>
                                {this.props.place}
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faClock}/>
                            </div>
                            <div className={styles.text}>
                                {this.props.time}
                            </div>
                        </div>

                        <div className={styles.lineContainer}>

                                {this.props.state === VALIDATE ?
                                    <div className={styles.labelGreen}>
                                        Votre inscription est validée. <br/>
                                        Merci de prevenir si vous ne pouvez pas venir.
                                    </div>
                                    :
                                    this.props.state === WAITLIST ?
                                        <div className={styles.labelOrange}>
                                            Vous êtes sur liste d'attente. <br/>
                                            Nous vous contacterons si des places se libèrent.
                                        </div>
                                        :
                                        this.props.state === WAITLIST_CONTACT ?
                                            <div className={styles.labelBlue}>
                                                Vous êtes sur liste d'attente mais nous vous avons contacté par e-mail. <br/>
                                                Merci de répondre rapidement.
                                            </div>
                                            :
                                            this.props.state === BASKET ?
                                                <div className={styles.labelRed}>
                                                    En attente de paiement
                                                </div>
                                                :
                                                null
                                }

                        </div>
                    </div>


                    <div className={styles.buttonContainer}>
                        <a href={this.props.link} className={globalStyles.button} style={{flex: 1}}>
                            {"Voir plus"}
                        </a>
                    </div>
                </div>


            </div>
        );
    }
}

export default CardEvent;
