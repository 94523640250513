import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Profile.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

class EditProfile extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            lastName: this.props.user.user.lastName,
            firstName: this.props.user.user.firstName,
            email: this.props.user.user.email,
            phone: this.props.user.user.phone,
            job: 0,
            jobs: [],
        }
        this.load = {
            job: {
                start: false,
                end: false,
            }
        }
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.getJobs()
    }

    getJobs() {
        if(!this.load.job.start){
            this.load.job.start = true
            let request = new Request(api_url + "/public/jobs", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, false).then(response => {
                return response.json()
            }).then((data) => {
                this.setState({jobs: data});
            })
        }
    }

    getSelectJobs() {
        let options = [];
        options.push(<option value="">Choisissez une option</option>);
        for(let key in this.state.jobs){
            options.push(
                <option value={key} selected={this.props.user.user.job === this.state.jobs[key]}>{this.state.jobs[key]}</option>
            )
            if(this.props.user.user.job === this.state.jobs[key] && this.state.job === 0) this.setState({job: key})
        }
        options.push(<option value="-1" selected={this.props.user.user.job === "Autre"}>Aucun de la liste</option>)
        if(this.props.user.user.job === "Autre" && this.state.job === 0) this.setState({job: -1})
        return options
    }

    isValidEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let syntax = re.test(String(this.state.email).toLowerCase());
        return this.state.email && this.state.email.length > 0 && syntax;
    }

    isValidLastName() {
        return this.state.lastName && this.state.lastName.length > 0
    }

    isValidFirstName() {
        return this.state.firstName && this.state.firstName.length > 0
    }

    isJobValid() {
        return this.state.job != null && this.state.job !== ""
    }

    isPhoneValid() {
        return this.state.phone != null && this.state.phone.length === 10;
    }

    isFormValid() {
        let emailValid = this.isValidEmail();
        let firstName = this.isValidFirstName();
        let lastName = this.isValidLastName();
        let job = this.isJobValid();
        let phone = this.isPhoneValid();

        return lastName && firstName && emailValid && job && phone;
    }

    edit() {
        let infos = new FormData();
        let data = {
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            job: this.state.job,
            phone: this.state.phone
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/profile/edit', {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.edited) {
                this.props.dispatch({
                    type: "CHANGED"
                })
                this.addFlash("success", "Votre profil a bien été mis à jour.")
                this.setState({navigateTo: "/profile"})
                this.setState({shouldNavigate: true})
            } else {
                this.setState({error: data.error})
            }
        })

    }


    render() {
        if(!this.canRender()) {
            return this.noRender()
        }

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Modifier mon profil</h1>

                        <div className={styles.container}>

                            <div style={{display: "flex", gap: "10px"}}>
                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Nom</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.lastName}
                                            onChange={(event) => {
                                                this.setState({lastName: event.target.value})
                                            }}
                                        />
                                        { this.isValidLastName() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Prénom</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.firstName}
                                            onChange={(event) => {
                                                this.setState({firstName: event.target.value})
                                            }}
                                        />
                                        { this.isValidFirstName() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{display: "flex", gap: "10px"}}>
                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Profession</label>
                                    <div className={globalStyles.inputArea}>
                                        <select
                                            className={globalStyles.inputInArea}
                                            value={this.state.job}
                                            onChange={(event) => this.setState({job: event.target.value})}
                                        >
                                            {this.getSelectJobs()}
                                        </select>

                                        {this.isJobValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Numéro de téléphone</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.phone}
                                            onChange={(event) => {
                                                this.setState({phone: event.target.value})
                                            }}
                                        />
                                        { this.isPhoneValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <button
                                className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                                style={{marginTop: "15px", padding: "10px 30px", alignSelf: "center"}}
                                disabled={!this.isFormValid()}
                                onClick={this.edit.bind(this)}
                            >
                                Enregistrer
                            </button>

                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(EditProfile);
