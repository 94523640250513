import {createStore, applyMiddleware} from '@reduxjs/toolkit';
import {createStateSyncMiddleware} from "redux-state-sync";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./reducers/userReducer";
import flashReducer from "./reducers/flashReducer";

const persistConfig = {
    key: "root",
    storage,
};

export default createStore(
    persistCombineReducers(persistConfig,{user: userReducer, flash: flashReducer}),
    applyMiddleware(createStateSyncMiddleware({
        blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    }))
);
