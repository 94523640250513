const initialState = {
    lastId: 0,
    ids : [],
    flash: {}
};

export default function flashReducer (state = initialState, action) {
    switch (action.type) {
        case "ADD_FLASH":
            let new_message = {
                type : action.flashType,
                content : action.flashContent
            }
            let id = state.lastId + 1
            let newFlash = {
                ...state.flash,
            }
            newFlash['flash'+id] = new_message
            let newIds = state.ids
            newIds.push(id)
            return {
                ...state,
                lastId: id,
                ids : newIds,
                flash: newFlash
            }
        case "REMOVE":
            let remIds = state.ids
            let ind = remIds.indexOf(action.id)
            if(ind > -1) {
                remIds.splice(ind, 1)
            }
            return {
                ...state,
                ids : remIds
            }
        default:
            return state
    }
}


