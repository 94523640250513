import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Register.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Message from "../Utils/Message/Message";
import ValidateEmail from "./ValidateEmail";

class Register extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            lastName: "",
            firstName: "",
            job: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            jobs: {},
            error: null,
            validation: false,
        }
        this.load = {
            job: {
                start: false,
                end: false,
            }
        }
    }

    doesMustLog() {
        this.mustLog = -1
    }

    loadData() {
        this.getJobs()
    }

    getJobs() {
        if(!this.load.job.start){
            this.load.job.start = true
            let request = new Request(api_url + "/public/jobs", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, false).then(response => {
                return response.json()
            }).then((data) => {
                this.setState({jobs: data});
            })
        }
    }

    getSelectJobs() {
        let options = [];
        options.push(<option value="">Choisissez une option</option>);
        for(let key in this.state.jobs){
            options.push(<option value={key}>{this.state.jobs[key]}</option>)
        }
        options.push(<option value="-1">Aucun de la liste</option>)
        return options
    }

    isValidEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let syntax = re.test(String(this.state.email).toLowerCase());
        return this.state.email && this.state.email.length > 0 && syntax;
    }

    isValidLastName() {
        return this.state.lastName && this.state.lastName.length > 0
    }

    isValidFirstName() {
        return this.state.firstName && this.state.firstName.length > 0
    }

    isPasswordValid() {
        return this.state.password && this.state.password.length >= 8
    }

    isConfirmPasswordValid() {
        return this.state.confirmPassword && this.state.confirmPassword.length >= 8 &&this.state.confirmPassword === this.state.password
    }

    isJobValid() {
        return this.state.job != null && this.state.job !== ""
    }

    isPhoneValid() {
        return this.state.phone && this.state.phone.length === 10;
    }

    isFormValid() {
        let emailValid = this.isValidEmail();
        let firstName = this.isValidFirstName();
        let lastName = this.isValidLastName();
        let job = this.isJobValid();
        let password = this.isPasswordValid();
        let confirmPassword = this.isConfirmPasswordValid();
        let phone = this.isPhoneValid();

        return lastName && firstName && emailValid && job && password && confirmPassword && phone;
    }

    register() {
        let infos = new FormData();
        let data = {
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            email: this.state.email,
            job: this.state.job,
            password: this.state.password,
            phone: this.state.phone
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/register', {method:'POST', body: infos});
        this.fetchResponseOrLoginOrError(request, false).then(response => {
            return response.json()
        }).then((data) => {
            if(data.created) {
                this.setState({validation: true})
            } else {
                this.setState({error: data.error})
            }
        })

    }

    render() {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div>
                {this.flashtoDisplay}
                {this.state.validation ?
                    <ValidateEmail email={this.state.email}/>
                    :
                    <div className={styles.container}>
                        <div className={globalStyles.leftPart}/>

                        <div className={globalStyles.rightPart}>
                            <div className={styles.content}>
                                <h1 className={styles.title}>Inscrivez-vous</h1>

                                { this.state.error &&
                                    <Message
                                        type={"danger"}
                                        title={"Oups, quelque chose s'est mal passé."}
                                        text={this.state.error}
                                    />
                                }


                                <div style={{display: "flex", gap: "10px"}}>
                                    <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                        <label className={globalStyles.label}>Nom</label>
                                        <div className={globalStyles.inputArea}>
                                            <input
                                                className={globalStyles.inputInArea}
                                                value={this.state.lastName}
                                                onChange={(event) => {
                                                    this.setState({lastName: event.target.value})
                                                }}
                                            />
                                            { this.isValidLastName() &&
                                                <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                            }
                                        </div>
                                    </div>
                                    <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                        <label className={globalStyles.label}>Prénom</label>
                                        <div className={globalStyles.inputArea}>
                                            <input
                                                className={globalStyles.inputInArea}
                                                value={this.state.firstName}
                                                onChange={(event) => {
                                                    this.setState({firstName: event.target.value})
                                                }}
                                            />
                                            { this.isValidFirstName() &&
                                                <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Profession</label>
                                    <div className={globalStyles.inputArea}>
                                        <select
                                            className={globalStyles.inputInArea}
                                            value={this.state.job}
                                            onChange={(event) => this.setState({job: event.target.value})}
                                        >
                                            {this.getSelectJobs()}
                                        </select>

                                        {this.isJobValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Adresse e-mail</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.email}
                                            onChange={(event) => {
                                                this.setState({email: event.target.value})
                                            }}
                                        />
                                        { this.isValidEmail() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Numéro de téléphone</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.phone}
                                            onChange={(event) => {
                                                this.setState({phone: event.target.value})
                                            }}
                                        />
                                        { this.isPhoneValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Mot de passe</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            type={"password"}
                                            className={globalStyles.inputInArea}
                                            value={this.state.password}
                                            onChange={(event) => {
                                                this.setState({password: event.target.value})
                                            }}
                                        />
                                        { this.isPasswordValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Confirmer le mot de passe</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            type={"password"}
                                            className={globalStyles.inputInArea}
                                            value={this.state.confirmPassword}
                                            onChange={(event) => {
                                                this.setState({confirmPassword: event.target.value})
                                            }}
                                        />
                                        { this.isConfirmPasswordValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>


                                <button
                                    className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                                    style={{marginTop: "15px"}}
                                    disabled={!this.isFormValid()}
                                    onClick={this.register.bind(this)}
                                >
                                    S'inscrire
                                </button>

                                <div className={styles.login}>
                                    Déjà un compte ?
                                    <a href={"/login"}>Connectez-vous</a>
                                </div>
                            </div>
                        </div>


                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(Register);
