let initialState = {
    userConnected : false,
    dateConnected : new Date(),
    user : {},
    userChanged : false
};


export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                userConnected: true,
                dateConnected : new Date(),
                user: action.user,
                userChanged: false
            }
        case "REFRESH":
            return {
                ...state,
                dateConnected : new Date()
            }
        case "CHANGED":
            return {
                ...state,
                userChanged: true
            }
        case "UPDATE":
            return {
                ...state,
                user: action.user,
                userChanged: false
            }
        case "LOGOUT":
            return initialState
        default:
            return state
    }
}