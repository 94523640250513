import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./ForgetPassword.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Message from "../Utils/Message/Message";
import NewPassword from "./NewPassword";

class ForgetPassword extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            error: null,
            validation: false,
        }
    }

    doesMustLog() {
        this.mustLog = -1
    }


    isValidEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let syntax = re.test(String(this.state.email).toLowerCase());
        return this.state.email && this.state.email.length > 0 && syntax;
    }



    isFormValid() {
        return this.isValidEmail();
    }

    forget() {
        let infos = new FormData();
        let data = {
            email: this.state.email,
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/forgetpassword', {method:'POST', body: infos});
        this.fetchResponseOrLoginOrError(request, false).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                this.setState({validation: true})
            } else {
                this.setState({error: data.error})
            }
        })

    }

    render() {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div>
                {this.flashtoDisplay}
                {this.state.validation ?
                    <NewPassword email={this.state.email}/>
                    :
                    <div className={styles.container}>
                        <div className={globalStyles.leftPart}/>

                        <div className={globalStyles.rightPart}>
                            <div className={styles.content}>
                                <h1 className={styles.title}>Mot de passe oublié</h1>

                                { this.state.error &&
                                    <Message
                                        type={"danger"}
                                        title={"Oups, quelque chose s'est mal passé."}
                                        text={this.state.error}
                                    />
                                }

                                <div>
                                    <p>
                                        Veuillez renseigner l'adresse e-mail de votre compte.
                                    </p>
                                </div>

                                <div className={globalStyles.inputContainer}>
                                    <label className={globalStyles.label}>Adresse e-mail</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.email}
                                            onChange={(event) => {
                                                this.setState({email: event.target.value})
                                            }}
                                        />
                                        { this.isValidEmail() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <button
                                    className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                                    style={{marginTop: "15px"}}
                                    disabled={!this.isFormValid()}
                                    onClick={this.forget.bind(this)}
                                >
                                    Suivant
                                </button>

                                <div className={styles.login}>
                                    Déjà un compte ?
                                    <a href={"/login"}>Connectez-vous</a>
                                </div>
                            </div>
                        </div>


                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(ForgetPassword);
