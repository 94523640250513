import React from "react"
import globalStyles from "../../../styles/globalStyles.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import styles from "./MyEvent.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchor,
    faCalendarDay,
    faClock,
    faMapMarkerAlt,
    faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {Elements} from "@stripe/react-stripe-js";
import SetupForm from "../../Utils/SetupForm/SetupForm";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;

const BASKET = 0;
const VALIDATE = 1;
const WAITLIST = 2;
const WAITLIST_CONTACT = 3;

const PAY_FREE = 0;
const PAY_CAUTION = 1;
const PAY_PAYING = 2;

class MyEvent extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            participation: null,
            clientSecret: "",
            hasAlreadySetup: false,
            donation: 0,
        };
        this.load = {
            event : {
                start: false,
                end: false
            },
            setupIntent: {
                start: false,
                end: false
            }
        }
        this.donation = "";
        this.loadNb = 0;
        this.displayLoading = false;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadParticipation()
    }

    loadParticipation() {
        if(!this.load.event.start) {
            this.load.event.start = true
            let request = new Request(api_url + "/myevent/" + this.props.participation, {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.event.end = true
                this.displayLoading = false
                if(data.success){
                    this.setState({participation: data.participation});
                    console.log(data.participation.state, WAITLIST_CONTACT)
                    if(data.participation.state === WAITLIST_CONTACT) {
                        console.log('LOAD INTENT')
                        this.loadSetupIntent()
                    }
                    if(data.participation.state === BASKET && this.loadNb < 4) {
                        this.displayLoading = true;
                        this.loadNb++;
                        setTimeout(() => {
                            this.load.event.start = false
                            this.loadParticipation();
                        }, 2000);
                    }
                    if(this.loadNb >= 4) {
                        this.displayLoading = false
                    }
                }
                else {
                    this.setState({shouldNavigate: true, navigateTo: "/myevents"});
                }
            })
        }
    }

    loadSetupIntent() {
        if(!this.load.setupIntent.start) {
            this.load.setupIntent.start = true
            let request = new Request(api_url + "/pay/setup/create", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.setupIntent.end = true
                if(data.hasAlready && !this.props.user.user.cardLastFour) {
                    this.mustSecure = true
                    this.props.dispatch({type: "CHANGED"})
                }
                this.setState({clientSecret: data.clientSecret, hasAlreadySetup: data.hasAlready});
            })
        }
    }

    getTime(event) {
        let start = event.startDate.date;
        let end = event.endDate.date;
        if(moment(start).format("DD/MM/YYYY") === moment(end).format("DD/MM/YYYY")){
            return "De " + moment(start).format("HH:mm") + " à " + moment(end).format("HH:mm");
        } else {
            return "Du " + moment(start).format("DD/MM/YYYY HH:mm") + " au " + moment(end).format("DD/MM/YYYY HH:mm");
        }
    }

    registerConfirmEvent() {
        let data = new FormData();
        let infos = {
            donation: this.state.donation
        }
        data.append("json", JSON.stringify(infos))
        let request = new Request(api_url + '/event/confirm/' + this.props.participation, {method:'POST', body: data, credentials: "include"});
        this.fetchResponseOrLoginOrError(request, false).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                if(data.redirectUrl) {
                    this.setState({shouldNavigate: true, navigateTo: data.redirectUrl})
                }
                else {
                    this.addFlash("success", "Votre inscription a bien été prise en compte.")
                    setTimeout(() => {
                        this.load.event.start = false
                        this.doRender = false
                        this.forceUpdate()
                    }, 100)
                }
            }
        })
    }

    getFraisStripe() {
        return Math.ceil(this.state.participation.event.payAmount*100*(1.5/98.5)+25*(100/98.5))/100
    }


    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>{this.state.participation.event.name}</h1>

                        <div style={{display: "flex", flexDirection: window.innerWidth > 1000 ? "row" : "column"}}>
                            <div className={styles.infosContainer}>
                                <div style={{textDecoration: "underline", fontWeight: 600}}>Informations</div>
                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={faCalendarDay}/>
                                    </div>
                                    <div className={styles.text}>
                                        <b>{moment(this.state.participation.event.startDate.date).format( "DD/MM/YYYY")}</b>
                                    </div>
                                </div>
                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={
                                            this.state.participation.event.type === SORTIE_MER ? faAnchor :
                                                this.state.participation.event.type === SOIREE_THEME ? faUsers :
                                                    this.state.participation.event.type === CONFERENCE ? faMicrophone :
                                                        null
                                        }/>
                                    </div>
                                    <div className={styles.text}>
                                        {this.state.participation.event.type === SORTIE_MER ? "Sortie en mer" :
                                            this.state.participation.event.type === SOIREE_THEME ? "Soirée à thème" :
                                                this.state.participation.event.type === CONFERENCE ? "Conférence" :
                                                    null
                                        }
                                    </div>
                                </div>
                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <div className={styles.text}>
                                        <b>{this.state.participation.event.place.name} - Antenne de {this.state.participation.event.place.antenne}</b> <br/>
                                        {this.state.participation.event.place.streetAddress} <br/>
                                        {this.state.participation.event.place.streetAddressMore &&
                                            <div>
                                                {this.state.participation.event.place.streetAddressMore} <br/>
                                            </div>
                                        }
                                        {this.state.participation.event.place.postCode} {this.state.participation.event.place.city} <br/>
                                    </div>
                                </div>
                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={faClock}/>
                                    </div>
                                    <div className={styles.text}>
                                        {this.getTime(this.state.participation.event)}
                                    </div>
                                </div>
                                <div>
                                    <div style={{fontWeight: "bold", marginBottom: "3px"}}>Description</div>
                                    <div>
                                        {this.state.participation.event.description}
                                    </div>
                                </div>

                            </div>
                            <div className={styles.persoContainer}>
                                <div className={styles.lineContainer}>

                                    {this.state.participation.state === VALIDATE ?
                                        <div className={styles.labelGreen}>
                                            Votre inscription est validée. <br/>
                                            Merci de prevenir si vous ne pouvez pas venir.
                                        </div>
                                        :
                                        this.state.participation.state === WAITLIST ?
                                            <div className={styles.labelOrange}>
                                                Vous êtes sur liste d'attente. <br/>
                                                Nous vous contacterons si des places se libèrent.
                                            </div>
                                            :
                                            this.state.participation.state === WAITLIST_CONTACT ?
                                                <div className={styles.labelBlue}>
                                                    Vous êtes sur liste d'attente mais nous vous avons contacté par e-mail. <br/>
                                                    Merci de répondre rapidement.
                                                </div>
                                                :
                                                this.state.participation.state === BASKET ?
                                                    <div className={styles.labelRed}>
                                                        <div style={{flex:1}}>En attente de paiement</div>
                                                        { this.displayLoading && <div style={{flex:1, alignItems: "center", justifyContent: "center"}}><div className={styles.overLoading}><div className={styles.loading}/></div></div>}
                                                    </div>
                                                    :
                                                    null
                                    }

                                </div>
                                { this.state.participation.state === WAITLIST_CONTACT && this.state.participation.event.payType != 1 &&
                                    <div style={{marginTop: 20}}>
                                        <h2 style={{marginBottom: "15px"}}>Faire un don</h2>
                                        <div>
                                            Vous pouvez ajouter un don à l'association Guérir en mer.
                                        </div>
                                        <div style={{display: "flex", flexDirection:"row"}}>
                                            <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                                <label className={globalStyles.label}>Don (€)</label>
                                                <input type={"text"} name={"donation"} className={globalStyles.input}
                                                       value={this.donation}
                                                       onChange={(event) => {
                                                           this.donation = event.target.value
                                                           this.forceUpdate()
                                                       }}
                                                />
                                            </div>
                                            <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"flex-end", padding: 10}}>
                                                <button onClick={() => {this.setState({donation: Math.max(0, Math.ceil(parseFloat(this.donation.replace(',', '.'))*100))})}} className={globalStyles.button} disabled={this.sending}>
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={styles.lineContainer}>
                                    {
                                        this.state.participation.state === WAITLIST_CONTACT ?
                                        <div style={{display: "flex"}}>
                                            {this.state.participation.event.payType === 1 ?
                                                <div className={styles.contentContainer}>
                                                    { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                        <div>
                                                            <div>
                                                                En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                Si vous ne venez pas, la caution de {this.state.participation.event.payAmount.toFixed(2)}€ sera capturée.<br/>
                                                                Si vous avez un empêchement, vous pourez nous contacter.<br/>
                                                                Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                            </div>
                                                            <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                <SetupForm id={this.props.participation} isEvent={false} isRegate={false}/>
                                                            </Elements>
                                                        </div>
                                                    }
                                                    { this.state.hasAlreadySetup &&
                                                        <div>
                                                            Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                            En cliquant sur le bouton <b>Valider</b>, vous vous inscrivez à l'événement et vous vous engagez à venir à l'événement.<br/>
                                                            Si vous ne venez pas, la caution de {this.state.participation.event.payAmount.toFixed(2)}€ sera capturée.<br/>
                                                            Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                            Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                            <div className={styles.buttonContainer}>
                                                                <button onClick={() => this.registerConfirmEvent()}
                                                                        className={globalStyles.button}>
                                                                    Valider l'inscription
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                this.state.participation.event.payType === 2 ?
                                                    <div className={styles.contentContainer}>
                                                        { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                            <div>
                                                                <div>
                                                                    En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                    Si vous avez un empêchement, vous pourez nous contacter.<br/>
                                                                    L'inscription à cet événement coûte {(this.state.participation.event.payAmount + this.getFraisStripe()).toFixed(2)}€ (dont {this.getFraisStripe().toFixed(2)}€ de frais de paiement).<br/>
                                                                    { this.state.donation > 0 && <div>Vous avez choisi de faire un don de {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.</div>}
                                                                    Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                                </div>
                                                                <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                    <SetupForm id={this.props.participation} isEvent={false} isRegate={false}/>
                                                                </Elements>
                                                            </div>
                                                        }
                                                        { this.state.hasAlreadySetup &&
                                                            <div>
                                                                Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                                En cliquant sur le bouton <b>Valider et payer</b>,
                                                                vous vous inscrivez à l'événement et payez {(this.state.participation.event.payAmount + this.getFraisStripe()).toFixed(2)}€ (dont {this.getFraisStripe().toFixed(2)}€ de frais de paiement{this.state.donation > 0 && <span> et {(this.state.donation/100).toFixed(2)}€ de don à Guérir en mer</span>})<br/>
                                                                Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                                Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                                <div className={styles.buttonContainer}>
                                                                    <button onClick={() => this.registerConfirmEvent()}
                                                                            className={globalStyles.button}>
                                                                        Valider et payer {(this.state.participation.event.payAmount + this.getFraisStripe() + (this.state.donation/100)).toFixed(2)}€
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    this.state.donation === 0 ?
                                                        <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            <div className={styles.contentContainer}>
                                                                <div>
                                                                    En cliquant sur le bouton <b>Valider</b>, vous vous
                                                                    inscrivez à
                                                                    l'événement et vous
                                                                    vous engagez à venir à l'événement. Si jamais vous avez un
                                                                    empêchement,
                                                                    vous pouvez nous contacter.

                                                                    Vous recevrez un e-mail de confirmation de votre
                                                                    inscription.
                                                                </div>

                                                                <div className={styles.buttonContainer}>
                                                                    <button onClick={() => this.registerConfirmEvent()}
                                                                            className={globalStyles.button}>
                                                                        Valider l'inscription
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : 0}}>
                                                            <div>
                                                                { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                                    <div>
                                                                        <div>
                                                                            En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                            Si vous avez un empêchement, vous pourrez nous contacter.<br/>
                                                                            Vous avez choisi de faire un don de {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.<br/>
                                                                            Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                                        </div>
                                                                        <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                            <SetupForm id={this.props.participation} isEvent={false} isRegate={false}/>
                                                                        </Elements>
                                                                    </div>
                                                                }
                                                                { this.state.hasAlreadySetup &&
                                                                    <div>
                                                                        Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                                        En cliquant sur le bouton <b>Valider et donner</b>,
                                                                        vous vous inscrivez à l'événement et faites un don de  {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.<br/>
                                                                        Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                                        Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                                        <div className={styles.buttonContainer}>
                                                                            <button onClick={() => this.registerConfirmEvent()}
                                                                                    className={globalStyles.button}>
                                                                                Valider et donner {(this.state.donation/100).toFixed(2)}€
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                            }
                                        </div>
                                            :
                                        <div style={{display: "flex", flex: 1}}>
                                            {
                                                this.state.participation.event.payType === PAY_CAUTION && this.state.participation.payStatus &&
                                                <div style={{flex: 1}}>
                                                    <div className={styles.card}>

                                                        <div style={{display: "flex", flexDirection: "column", fontSize: "14px", marginBottom: "10px"}}>
                                                            <div>
                                                                Vous n'êtes pas venu à l'événement. La caution a été capturée. <br/>
                                                            </div>
                                                            <div>
                                                                {this.state.participation.payStatus === "succeeded" ?
                                                                    "Le paiement a été effectué avec succès." :
                                                                    this.state.participation.payStatus === "processing" ?
                                                                        "Le paiement est en cours." :
                                                                        "Le paiement a échoué."
                                                                }
                                                            </div>
                                                        </div>

                                                        <div style={{fontWeight: 600, textDecoration: "underline"}}>
                                                            Récapitulatif du paiement
                                                        </div>

                                                        <div className={styles.lineContainer}>
                                                            <div style={{flex: 1}}>Caution </div>
                                                            <div style={{textAlign: "right", display: "flex", justifyContent: "flex-end", flex: 1}}>
                                                                {this.state.participation.event.payAmount.toFixed(2) + "€"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: "20px"}}>
                                                        Si vous avez besoin d'une facture, contactez <a href={"mailto:contact@guerirenmer.com"}>contact@guerirenmer.com</a>
                                                    </div>
                                                </div>

                                            }

                                            {
                                                this.state.participation.event.payType === PAY_PAYING && this.state.participation.payStatus &&
                                                <div style={{flex: 1}}>
                                                    <div className={styles.card}>

                                                        <div style={{fontWeight: 600, textDecoration: "underline"}}>
                                                            Récapitulatif de commande
                                                        </div>

                                                        <div className={styles.lineContainer}>
                                                            <div style={{flex: 1}}>Inscription </div>
                                                            <div style={{textAlign: "right", display: "flex", justifyContent: "flex-end", flex: 1}}>
                                                                {this.state.participation.event.payAmount.toFixed(2) + "€"}
                                                            </div>
                                                        </div>
                                                        <div className={styles.lineContainer}>
                                                            <div style={{flex: 1}}>Frais de paiement </div>
                                                            <div style={{textAlign: "right", display: "flex", justifyContent: "flex-end", flex: 1}}>
                                                                {this.getFraisStripe().toFixed(2) + "€"}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{marginTop: "20px"}}>
                                                        Si vous avez besoin d'une facture, contactez <a href={"mailto:contact@guerirenmer.com"}>contact@guerirenmer.com</a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(MyEvent);
