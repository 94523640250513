import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./CardEvent.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAnchor,
    faCalendarDay,
    faClock, faLock,
    faMapMarkerAlt,
    faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;

const CLOSE = 2;

class CardEvent extends Component {


    render () {

        return (
            <div className={styles.card}>

                <div className={styles.titleContainer}>
                    {this.props.titre}
                </div>

                <div className={styles.contentContainer}>
                    <div className={styles.infosContainer}>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faCalendarDay}/>
                            </div>
                            <div className={styles.text}>
                                <b>{this.props.date}</b>
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon
                                    icon={
                                        this.props.type === SORTIE_MER ? faAnchor :
                                            this.props.type === SOIREE_THEME ? faUsers :
                                                this.props.type === CONFERENCE ? faMicrophone :
                                                    null
                                    }
                                />
                            </div>
                            <div className={styles.text}>
                                {this.props.type === SORTIE_MER ? "Sortie en mer" :
                                    this.props.type === SOIREE_THEME ? "Soirée à thème" :
                                        this.props.type === CONFERENCE ? "Conférence" :
                                            null
                                }
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            </div>
                            <div className={styles.text}>
                                {this.props.place}
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faClock}/>
                            </div>
                            <div className={styles.text}>
                                {this.props.time}
                            </div>
                        </div>
                        <div className={styles.lineContainer}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faUsers}/>
                            </div>
                            <div className={styles.text}>
                                {this.props.capacity === 0 ? "Pas de limite de place" : this.props.capacity + " places"}
                            </div>
                        </div>
                        {!(this.props.capacity === 0) &&
                            <div className={styles.remainingPlace} style={{color: this.props.available > 10 ? "var(--green)" : this.props.available > 0 ? "var(--orange)": "var(--red)"}}>
                                {this.props.available > 0 ? this.props.available + " places disponibles" : "Plus de place disponible"}
                            </div>
                        }

                    </div>

                    <div className={styles.price}>
                        {this.props.price}
                        {this.props.caution &&
                            <div style={{fontSize: "12px"}}>
                                Une caution de {this.props.caution} vous sera demandée.
                            </div>
                        }
                    </div>

                    <div className={styles.buttonContainer}>
                        {!this.props.job ?
                            <div style={{margin: "20px auto", textAlign: "center", color: "var(--red)", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faLock} color={"var(--red)"} style={{marginRight: "0.5rem"}}/>
                                Cet événement est reservé aux soignants
                            </div>
                            : this.props.register ?
                            <div style={{margin: "auto", color: "var(--green)", fontWeight: "bold"}}>Vous êtes déjà inscrit à cet événement.</div>
                            :
                            this.props.state === CLOSE ?
                                <div style={{margin: "auto", color: "var(--blue)", fontWeight: "bold"}}>Les inscriptions à cet événement sont fermées.</div>
                                :
                                <a href={this.props.link} className={globalStyles.button} style={{flex: 1}}>
                                    {"Voir plus"}
                                </a>
                        }
                    </div>
                </div>


            </div>
        );
    }
}

export default CardEvent;
