import styles from './Message.module.css';
import React, {Component} from "react"
import logo from "../../../assets/logos/logo_green.png";
import {faCheckCircle, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import globalStyles from "../../../styles/globalStyles.module.css";

class Message extends Component {

    render () {

        return (
            <div className={this.props.type === "success" ? styles.success : this.props.type === "warning" ? styles.warning : this.props.type === "danger" ? styles.danger : null}>
                <div className={styles.title}>
                    <FontAwesomeIcon
                        icon={this.props.type === "success" ? faCheckCircle : faExclamationTriangle}
                        className={this.props.type === "success" ? styles.iconSuccess : this.props.type === "warning" ? styles.iconWarning : this.props.type === "danger" ? styles.iconDanger : null}
                    />
                    {this.props.title}
                </div>
                <div>{this.props.text}</div>

                {this.props.button &&
                    <a href={this.props.button.link} className={globalStyles.button} style={{width: "50%", margin: "10px auto", }}>
                        {this.props.button.text}
                    </a>
                }
            </div>
        );
    }
}

export default Message;
