import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import TemplateComponent, {mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit} from "@fortawesome/free-solid-svg-icons";


class Home extends TemplateComponent {


    doesMustLog() {
        this.mustLog = 1
    }


    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer} style={{display: "flex", flexDirection: "column", gap: "100px", alignItems: "center", justifyContent: "center"}}>

                        <a
                            className={globalStyles.buttonRound}
                            href={"/regate"}
                            style={{textAlign: "center", minWidth: "min(500px, 80vw)", fontSize: "20px"}}
                        >
                            Evénement annuel - Régate
                        </a>

                        <a
                            className={globalStyles.buttonRound}
                            href={"/parcours"}
                            style={{textAlign: "center", minWidth: "min(500px, 80vw)", fontSize: "20px"}}
                        >
                            Sorties en mer - Parcours GEM
                        </a>

                        <a
                            className={globalStyles.buttonRound}
                            href={"/myevents"}
                            style={{textAlign: "center", minWidth: "min(500px, 80vw)", fontSize: "20px"}}
                        >
                            Mes sorties
                        </a>


                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Home);
