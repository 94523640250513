import styles from './Empty.module.css';
import React, {Component} from "react"
import logo from "../../../assets/logos/logo_green.png";

class Empty extends Component {

    render () {

        return (
            <div className={styles.div_100vh}>
                <img src={logo} className={styles.logo} alt={"Logo"}/>
            </div>
        );
    }
}

export default Empty;
