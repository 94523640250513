import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Profile.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

class EditProfile extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: ""
        }
    }

    doesMustLog() {
        this.mustLog = 1
    }

    isPasswordValid() {
        return this.state.password && this.state.password.length >= 8
    }

    isConfirmPasswordValid() {
        return this.state.confirmPassword && this.state.confirmPassword.length >= 8 &&this.state.confirmPassword === this.state.password
    }


    isFormValid() {
        let password = this.isPasswordValid();
        let confirmPassword = this.isConfirmPasswordValid();

        return password && confirmPassword;
    }

    changePassword() {
        let infos = new FormData();
        let data = {
            password: this.state.password
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/password/edit', {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.changed) {
                this.addFlash("success", "Votre mot de passe a bien été mis à jour.")
                this.setState({navigateTo: "/profile"})
                this.setState({shouldNavigate: true})
            } else {
                this.setState({error: data.error})
            }
        })

    }


    render() {
        if(!this.canRender()) {
            return this.noRender()
        }

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Modifier mon mot de passe</h1>

                        <div className={styles.container}>

                            <div style={{display: "flex", gap: "10px"}}>
                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Mot de passe</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.password}
                                            onChange={(event) => {
                                                this.setState({password: event.target.value})
                                            }}
                                            type={"password"}
                                        />
                                        { this.isPasswordValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>

                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                    <label className={globalStyles.label}>Confirmation du mot de passe</label>
                                    <div className={globalStyles.inputArea}>
                                        <input
                                            className={globalStyles.inputInArea}
                                            value={this.state.confirmPassword}
                                            onChange={(event) => {
                                                this.setState({confirmPassword: event.target.value})
                                            }}
                                            type={"password"}
                                        />
                                        { this.isConfirmPasswordValid() &&
                                            <FontAwesomeIcon icon={faCheckCircle} className={globalStyles.iconInput}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <button
                                className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                                style={{marginTop: "15px", padding: "10px 30px", alignSelf: "center"}}
                                disabled={!this.isFormValid()}
                                onClick={this.changePassword.bind(this)}
                            >
                                Enregistrer
                            </button>

                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(EditProfile);
