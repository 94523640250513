import {Component} from "react";
import Flash from "../components/Utils/Flash/Flash";
import {Navigate} from "react-router-dom";
import Empty from "../components/Utils/Empty/Empty";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import env from "../.env.json"

export const api_url = env["API_URL"];
export const app_url = env["APP_URL"];
export const stripe_pk = env["STRIPE_PK"];

export const mapStateToProps = (state) => state

export function addFlash(dispatch, type, content) {
    dispatch({
        type:"ADD_FLASH",
        flashType: type,
        flashContent: content
    })
}

export default class TemplateComponent extends Component {
    constructor(props) {
        super(props);
        this.mustSecure = true
        this.mustLog = 0
        this.isConnected = false
        this.hasdisplayedFlash = false
        this.flashtoDisplay = []
        this.doRender = false
        this.state = {
            shouldNavigate: false,
            navigateTo: '/',
            isOK: false,
        }
    }

    addFlash(type, content){
        addFlash(this.props.dispatch.bind(this), type, content)
    }

    hydrateDisplayFlash() {
        if(!this.hasdisplayedFlash) {
            this.hasdisplayedFlash = true
            let res = []
            let flash, id
            let ids = this.props.flash.ids
            for(const i in ids) {
                id = ids[i]
                flash = this.props.flash.flash['flash'+id]
                res.push(<Flash type={flash.type} content={flash.content} flashKey={id}/>)
                this.props.dispatch({
                    type: "REMOVE",
                    id: id
                })
            }
            this.flashtoDisplay = res
        }
    }

    updateUser() {
        let request = new Request(api_url + "/me", {method: "GET", credentials: "include"})
        this.fetchResponseOrLoginOrError(request, true, null, true).then((response) => {
            return response.json()
        }).then((data) => {
            this.props.dispatch({
                type: "UPDATE",
                user: data
            })
            this.setState({isOK: true})
        })
    }

    security() {
        this.doesMustLog()
        this.isConnected = this.props.user.userConnected && (new Date(this.props.user.dateConnected) > new Date(new Date().getTime() - 24 * 60000))
        if(!this.isConnected && this.mustLog === 1) {
            this.setState({shouldNavigate: true, navigateTo: '/login'})
        }
        if (this.isConnected && this.mustLog === -1) {
            this.setState({shouldNavigate: true, navigateTo: '/'})
        }
        if (this.isConnected && this.mustLog === 1) {
            if(this.props.user.userChanged) {
                this.updateUser()
            }
            else {
                this.setState({isOK: true})
            }
        }
        if (this.mustLog === 0 || (this.mustLog === -1 && !this.isConnected)) {
            this.setState({isOK: true})
        }
    }

    async fetchResponseOrLoginOrError(request, needConnection = true, customError = null, skipRefresh = false)
    {
        if(needConnection && (!this.props.user.userConnected || (new Date(this.props.user.dateConnected) < new Date(new Date().getTime() - 24 * 60000)))) {
            this.goLogin()
            return
        }
        return fetch(request).then((response) => {
            if(response.status === 200) {
                if(this.props.user.userConnected && needConnection && !skipRefresh) {
                    this.props.dispatch({
                        type: "REFRESH"
                    })
                }
                return response
            } else if (response.status === 501) {
                this.goLogin()
            } else if (customError) {
                customError()
            } else {
                this.goError()
            }
        })
    }

    goError() {
        this.setState({shouldNavigate: true, navigateTo: '/error'})
    }

    goLogin() {
        this.setState({shouldNavigate: true, navigateTo: '/login'})
    }

    navigate() {
        if(this.state.navigateTo.startsWith('https://')) {
            window.location.replace(this.state.navigateTo)
            return (<Empty/>)
        }
        return (
            <Navigate to={this.state.navigateTo}/>
        )
    }

    setDoRender = () => {
        this.doRender = true
        this.forceUpdate()
    }

    canRender() {
        return !this.state.shouldNavigate && this.state.isOK && !this.mustSecure && this.doRender
    }

    noRender() {
        if(this.mustSecure) {
            this.security()
            this.mustSecure = false
            return <Empty/>
        }
        if(this.state.shouldNavigate){
            return this.navigate();
        }
        if(!this.state.isOK){
            return <Empty/>
        }
        if(!this.doRender){
            this.loadData()
            this.hydrateDisplayFlash()
            setTimeout(this.setDoRender.bind(this), 500)
            return <Empty/>
        }
        return <Empty/>
    }

    head() {
        return <Header user={this.props.user}/>
    }

    foot() {
        return <Footer/>
    }

    doesMustLog() { // implement
        this.mustLog = 0
    }

    loadData() {} // implement

    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight:  "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}
                <div>Content goes here</div>
                {this.foot()}
            </div>
        )
    }
}

