import React from 'react'
import { useParams } from 'react-router-dom';
import Maslach from "./Maslach";

function GetMaslachId () {
    let { id } = useParams();

    return(
        <Maslach event={id}/>
    )
}

export default GetMaslachId;
