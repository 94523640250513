import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Footer.module.css"
import logo from "../../assets/logos/logo_horizontal.png"

class Footer extends Component {


    render () {

        return (
            <footer className={styles.footer}>

                Guérir en mer

            </footer>
        );
    }
}

export default Footer;
