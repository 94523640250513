import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Regate.module.css"
import TemplateComponent from "../../services/APITemplate";


class Participant extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            participantData: this.props.participantData,
            regateEventInfos: this.props.regateEventInfos,
            packCompo: this.getBinary(this.props.regateEventInfos.packCompo),
            partIn: this.getBinary(this.props.participantData.participateIn),
            canGoNext: false,
        }
    }

    getBinary(n){
        let binary = n.toString(2).split('').reverse()
        while(binary.length < 4) {
            binary.push("0")
        }
        return binary
    }

    getInfos() {
        return this.state.participantData;
    }

    updateParticipateIn(i) {
        let partIn = [...this.state.participantData.participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        partIn[i] = (partIn[i] === "0" ? "1" : "0")

        this.setState({partIn: [...partIn], participantData: {...this.state.participantData, participateIn: parseInt([...partIn].reverse().join(''), 2)}} )


    }

    getPack() {
        let partIn = [...this.state.participantData.participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        for(let i = 0; i < this.state.packCompo.length; i++) {
            if(this.state.packCompo[i] === "1") {
                partIn[i] = "1";
            }
        }
        let participateIn = parseInt([...partIn].reverse().join(''), 2)
        this.setState({partIn: [...partIn]})
        this.state.participantData.participateIn = participateIn;
        this.forceUpdate();

    }

    getPrice(price){
        if(price === 0) return "Gratuit";
        return price.toFixed(2) + " €";
    }

    canGoNext() {
        let can = this.state.participantData.lastName !== "" &&
            this.state.participantData.firstName !== "" &&
            this.state.participantData.email !== "" &&
            this.state.participantData.phone !== "" &&
            this.state.participantData.participateIn !== 0;
        if(can !== this.props.currentCanNext) {
            this.props.canNext(can)
        }
    }

    render () {
        this.canGoNext()

        return (
            <div>

                <div className={styles.content} style={{overflow: 'auto', paddingBottom: 100}}>
                    <h1>Inscription</h1>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom*</label>
                            <input type={"text"} name={"lastName"} className={globalStyles.input}
                                   value={this.state.participantData.lastName}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, lastName: event.target.value}})

                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Prénom*</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.participantData.firstName}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, firstName: event.target.value}})

                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Adresse e-mail*</label>
                            <input type={"text"} name={"email"} className={globalStyles.input}
                                   value={this.state.participantData.email}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, email: event.target.value}})

                                   }}
                                   disabled
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de téléphone*</label>
                            <input type={"text"} name={"phone"} className={globalStyles.input}
                                   value={this.state.participantData.phone}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, phone: event.target.value}})

                                   }}
                                   disabled
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de licence FFV</label>
                            <input
                                type={"text"} name={"ffv"} className={globalStyles.input}
                                value={this.state.participantData.ffv}
                                onChange={(event) => {
                                    this.setState({participantData: {...this.state.participantData, ffv: event.target.value}})

                                }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Niveau</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.participantData.sailingLevel}
                                    onChange={(event) => {
                                        this.state.participantData.sailingLevel = event.target.value;
                                        this.forceUpdate();
                                    }}
                                >
                                    <option value={0}>Je n'ai jamais fait de voile</option>
                                    <option value={1}>J'ai des bases en voile</option>
                                    <option value={2}>J'ai des compétences en voile</option>
                                    <option value={3}>J'ai déjà regaté</option>
                                    <option value={4}>Je suis regatier confirmé</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow} >
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Sexe</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.participantData.sexe}
                                    onChange={(event) => {
                                        this.setState({participantData: {...this.state.participantData, sexe: event.target.value}})

                                    }}
                                >
                                    <option value={0}>Homme</option>
                                    <option value={1}>Femme</option>
                                </select>
                            </div>
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Taille de t-shirt</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.participantData.shirtSize}
                                    onChange={(event) => {
                                        this.setState({participantData: {...this.state.participantData, shirtSize: event.target.value}})

                                    }}
                                >
                                    <option value={0}>XS</option>
                                    <option value={1}>S</option>
                                    <option value={2}>M</option>
                                    <option value={3}>L</option>
                                    <option value={4}>XL</option>
                                    <option value={5}>XXL</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom de la personne avec qui vous souhaitez naviguer (facultatif)</label>
                            <input type={"text"} name={"navigateWith"} className={globalStyles.input}
                                   value={this.state.participantData.navigateWith}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, navigateWith: event.target.value}})

                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom du bateau sur lequel vous souhaitez naviguer (facultatif)</label>
                            <input type={"text"} name={"navigateOn"} className={globalStyles.input}
                                   value={this.state.participantData.navigateOn}
                                   onChange={(event) => {
                                       this.setState({participantData: {...this.state.participantData, navigateOn: event.target.value}})

                                   }}
                            />
                        </div>
                    </div>



                    <div className={styles.infoDiv}>
                        <b>L'évènement annuel “Guérir en mer” 4è édition à Marseille est sur 3 jours : 1 jour à terre et 2 jours en mer.</b><br/><br/>

                        L'événement sur l'eau est un événement sportif comportant une régate (compétition) organisée avec le soutien de la Société Nautique de Marseille, club affilié à la Fédération Française de Voile (FFV). <b>La participation à la régate REQUIERT une licence club compétition en cours de validité au moment de l'évènement.</b> Cette licence comprend entre autres une assurance décrite <a href="https://www.ffvoile.fr/ffv/web/services/assurances.asp">ici</a>.<br/><br/>

                        <b style={{color: 'var(--red)'}}>Chaque participant est responsable et autonome quant à l'obtention de sa licence.</b><br/><br/>

                        <b>Si vous disposez déjà d’une licence valide pour l’année en cours</b>, veuillez indiquer son numéro (cf dans le formulaire ci-dessus).<br/>

                        <b>Sinon</b>, vous devez en acheter une et vous disposez alors de 2 options (coût en sus des frais d'inscription) :
                        <ul>
                            <li>une licence compétition temporaire de 4 jours = 34 €</li>
                            <li> licence compétition annuelle = 68€ </li>
                        </ul>
                        En passant par le bureau des régates de la Société Nautique de Marseille avant le 12 juin 2024  (par téléphone au 04 91 54 32 03 ou par mail <a href="mailto:regate@lanautique.com">regate@lanautique.com</a>)<br/><br/>
<b>
    Avis de course: <a href="https://www.lanautique.com/wp-content/uploads/2023/10/AVIS-DE-COURSE-GUERIR-24-1.pdf" target="_blank">ICI</a>
</b>
                        <b style={{color: 'var(--red)'}}>Attention : En l'absence de licence valide, les frais d'inscriptions ne seront pas remboursés et la participation aux activités sur l'eau impossible. En cas d'annulation de participation ou de l'événement les frais de la licence ne pourront être remboursés. <a href="https://guerirenmer.com/mentions-legales-cgu-2/" target="_blank">CGV</a> disponibles sur le site www.guerirenmer.com</b>
                    </div>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canColloque) return;
                                     if(this.state.participantData.packWe === true && this.state.packCompo[1] === "1") return;
                                     this.updateParticipateIn(1);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.partIn[1] === "1"}
                                       disabled={(this.state.participantData.packWe === true && this.state.packCompo[1] === "1") || !this.props.regateEventInfos.canColloque}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canColloque ? 'auto' : 'line-through'}}>Journée du 21 juin : matinée bien-être et colloque "Voile et Santé" (réservée aux soignants et seront prioritaires les soignants inscrits pour la totalité du week-end GEM)</span>
                                { this.props.regateEventInfos.canColloque ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.colloquePrice)}</span>
                                :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canDinner) return;
                                     if(this.state.participantData.packWe === true && this.state.packCompo[2] === "1") return;
                                     this.updateParticipateIn(2);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.partIn[2] === "1"}
                                       disabled={(this.state.participantData.packWe === true && this.state.packCompo[2] === "1") || !this.props.regateEventInfos.canDinner}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canDinner ? 'auto' : 'line-through'}}>Soirée "GEM en Musique" du vendredi 21 juin à l'UNM</span>
                                { this.props.regateEventInfos.canDinner ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.dinnerPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if (!this.props.regateEventInfos.canRegate) return;
                                     if (this.state.participantData.packWe === true && this.state.packCompo[0] === "1") return;
                                     this.updateParticipateIn(0);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.partIn[0] === "1"}
                                       disabled={(this.state.participantData.packWe === true && this.state.packCompo[0] === "1") || !this.props.regateEventInfos.canRegate}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canRegate ? 'auto' : 'line-through'}}>2 jours sur l'eau : régate du 22 juin et régate du 23 juin (ne comprend pas la soirée du 21 juin)</span>
                                { this.props.regateEventInfos.canRegate ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.regatePrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canParty) return;
                                     if(this.state.participantData.packWe === true && this.state.packCompo[3] === "1") return;
                                     this.updateParticipateIn(3);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.partIn[3] === "1"}
                                       disabled={(this.state.participantData.packWe === true && this.state.packCompo[3] === "1") || !this.props.regateEventInfos.canParty}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canParty ? 'auto' : 'line-through'}}>Soirée festive du samedi 22 juin à La Nautique</span>
                                { this.props.regateEventInfos.canParty ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.partyPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        );
    }
}

export default Participant;
