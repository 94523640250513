import React from 'react'
import { useParams } from 'react-router-dom';
import MyRegate from "./MyRegate";

function GetMyRegateId () {
    let { id } = useParams();

    return(
        <MyRegate order={id}/>
    )
}

export default GetMyRegateId;
