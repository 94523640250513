import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Login.module.css"
import circleLogo from "../../assets/logos/logo_circle.png"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import Message from "../Utils/Message/Message";

class Login extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            error: false
        }
    }

    doesMustLog() {
        this.mustLog = -1
    }

    submit() {
        let data = {
            _username: this.state.username,
            _password: this.state.password,
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(api_url + "/log", {credentials: "include", body: jsonD, method: "POST"})
        fetch(request).then((response) => {
            switch (response.status) {
                case 200:
                    return response.json()
                case 401:
                case 404:
                default:
                    this.setState({error: true})
                    return false
            }
        }).then((data) => {
            if(data) {
                this.props.dispatch({
                    type: "LOGIN",
                    user: data
                })
                this.setState({shouldNavigate: true, navigateTo: '/'})
            }
        })
    }

    render () {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div className={styles.container}>
                {this.flashtoDisplay}
                <div className={globalStyles.leftPart}/>

                <div className={globalStyles.rightPart}>
                    <div className={styles.content}>
                        <img src={circleLogo} style={{height: "120px", width: "120px", margin: "0 auto 20px"}}/>
                        <h1 className={styles.title}>Bienvenue</h1>
                        <h2 className={styles.subtitle}>Portail d'inscription de l'association GUÉRIR EN MER</h2>

                        { this.state.error &&
                            <Message
                                type={"danger"}
                                title={"Identifiant ou mot de passe incorrect."}
                                text={"Veuillez réessayer."}
                            />
                        }

                        <div className={globalStyles.inputContainer}>
                            <label className={globalStyles.label}>Adresse e-mail</label>
                            <input
                                type="text"
                                name="username"
                                size={1}
                                className={globalStyles.input}
                                required placeholder="E-Mail"
                                value={this.state.username}
                                onChange={(event) => {
                                    let cleanMail = event.target.value.replace(/\s/g, "");
                                    this.setState({username: cleanMail.toLowerCase(), error:false})
                                }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer}>
                            <label className={globalStyles.label}>Mot de passe</label>
                            <input
                                type="password"
                                name="password"
                                size={1}
                                className={globalStyles.input}
                                required placeholder="Mot de passe"
                                value={this.state.password}
                                onChange={(event) => {
                                    this.setState({password: event.target.value, error:false})
                                }}
                            />
                        </div>

                        <div className={styles.rememberAndForgetContainer}>
                            <a style={{alignSelf: "flex-end"}} href={"/forgetpassword"}>
                                Mot de passe oublié
                            </a>
                        </div>

                        <button className={globalStyles.button} style={{marginTop: "15px"}} onClick={this.submit.bind(this)}>
                            Se connecter
                        </button>

                        <div className={styles.register}>
                            Pas encore de compte ?
                            <a href={"/register"}>Inscrivez-vous</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Login);
