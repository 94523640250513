import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import Register from "./components/Register/Register";
import Parcours from "./components/Parcours/Parcours";
import Regate from "./components/Regate/Regate";
import Maslach from "./components/Maslach/Maslach";
import GetEventId from "./components/Parcours/Event/GetEventId";
import GetMaslachId from "./components/Maslach/GetMaslachId";
import GetRegisterEventId from "./components/Parcours/RegisterEvent/GetRegisterEventId";
import MyEvents from "./components/MyEvent/MyEvents";
import GetMyEventId from "./components/MyEvent/MyEvent/GetMyEventId";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import EditPassword from "./components/Profile/EditPassword";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import Error from "./components/Utils/Empty/Error";
import NotFound from "./components/Utils/Empty/NotFound";
import GetMyRegateId from "./components/MyEvent/MyRegate/GetMyRegate";
import GetConfirmAndPayId from "./components/Regate/GetConfirmAndPayId";
import GetEditRegateId from "./components/MyEvent/EditRegate/GetEditRegate";
import Home from "./components/Home/Home";

function App() {
  return (
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/logout" element={<Logout/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path={"/forgetpassword"} element={<ForgetPassword/>}/>

          <Route path="/parcours" element={<Parcours/>}/>
          <Route path="/event/:id" element={<GetEventId/>}/>
          <Route path="/event/register/:id" element={<GetRegisterEventId/>}/>
          <Route path="/regate" element={<Regate/>}/>
          <Route path="/regate/register/:id" element={<GetConfirmAndPayId/>}/>
          <Route path="/maslach" element={<Maslach/>}/>
          <Route path="/maslach/:id" element={<GetMaslachId/>}/>

          <Route path="/myevents" element={<MyEvents/>}/>
          <Route path="/myevent/:id" element={<GetMyEventId/>}/>

          <Route path="/myregate/:id" element={<GetMyRegateId/>}/>
          <Route path="/edit/regate/:id" element={<GetEditRegateId/>}/>


          <Route path={"/profile"} element={<Profile/>}/>
          <Route path={"/profile/edit"} element={<EditProfile/>}/>
          <Route path={"/password/edit"} element={<EditPassword/>}/>

          <Route path="/error" element={<Error/>}/>
          <Route path="*" element={<NotFound/>}/>
      </Routes>
  );
}

export default App;
