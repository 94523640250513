import React from "react"
import globalStyles from "../../../styles/globalStyles.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import styles from "./EditRegate.module.css";

class EditRegate extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            order: null,
        };
        this.load = {
            order : {
                start: false,
                end: false
            },
        }
        this.loadNb = 0;
        this.displayLoading = false;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadOrder()
    }

    loadOrder() {
        if(!this.load.order.start) {
            this.load.order.start = true
            let request = new Request(api_url + "/myregate/" + this.props.order, {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.order.end = true
                this.displayLoading = false
                if(data.success){
                    this.setState({order: data.order});
                } else {
                    this.setState({shouldNavigate: true, navigateTo: "/myevents"});
                }
            })
        }
    }

    getBoatsForm() {
        let boats = [];
        for(let i = 0; i < this.state.order.boats.length; i++) {
            boats.push(
                <div>
                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom du bateau*</label>
                            <input type={"text"} name={"name"} className={globalStyles.input}
                                   value={this.state.order.boats[i].name}
                                   onChange={(event) => {
                                       this.setState({order: {...this.state.order, boats: [{...this.state.order.boats[i], name: event.target.value}]}})
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Marque/modèle*</label>
                            <input type={"text"} name={"model"} className={globalStyles.input}
                                   value={this.state.order.boats[i].model}
                                   onChange={(event) => {
                                       this.setState({order: {...this.state.order, boats: [{...this.state.order.boats[i], model: event.target.value}]}})
                                   }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Longueur (en m)</label>
                            <input type={"text"} name={"length"} className={globalStyles.input}
                                   value={this.state.order.boats[i].length}
                                   onChange={(event) => {
                                       this.setState({order: {...this.state.order, boats: [{...this.state.order.boats[i], length: event.target.value}]}})
                                   }}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return boats
    }

    getParticipantsForm() {
        let members = [];
        for(let i = 0; i < this.state.order.participations.length; i++) {
            members.push(
                <div>
                    {this.state.order.participations.length > 1 &&<h3>Membre {i + 1}</h3>}

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom</label>
                            <input type={"text"} name={"lastName"} className={globalStyles.input}
                                   value={this.state.order.participations[i].lastName}
                                   onChange={(event) => {
                                        this.state.order.participations[i].lastName = event.target.value;
                                        this.forceUpdate();
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Prénom</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.order.participations[i].firstName}
                                   onChange={(event) => {
                                        this.state.order.participations[i].firstName = event.target.value;
                                        this.forceUpdate();
                                   }}
                            />
                        </div>
                    </div>


                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de licence FFV</label>
                            <input
                                type={"text"} name={"ffv"} className={globalStyles.input}
                                value={this.state.order.participations[i].licence}
                                onChange={(event) => {
                                    this.state.order.participations[i].licence = event.target.value;
                                    this.forceUpdate();
                                }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Niveau</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.order.participations[i].sailingLevel}
                                    onChange={(event) => {
                                        this.state.order.participations[i].sailingLevel = event.target.value;
                                        this.forceUpdate();
                                    }}
                                >
                                    <option value={0}>Je n'ai jamais fait de voile</option>
                                    <option value={1}>J'ai des bases en voile</option>
                                    <option value={2}>J'ai des compétences en voile</option>
                                    <option value={3}>J'ai déjà regaté</option>
                                    <option value={4}>Je suis regatier confirmé</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Sexe</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.order.participations[i].gender}
                                    onChange={(event) => {
                                        this.state.order.participations[i].gender = event.target.value;
                                        this.forceUpdate();

                                    }}
                                >
                                    <option value={0}>Homme</option>
                                    <option value={1}>Femme</option>
                                </select>
                            </div>
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Taille de t-shirt</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.order.participations[i].size}
                                    onChange={(event) => {
                                        this.state.order.participations[i].size = event.target.value;
                                        this.forceUpdate();

                                    }}
                                >
                                    <option value={0}>XS</option>
                                    <option value={1}>S</option>
                                    <option value={2}>M</option>
                                    <option value={3}>L</option>
                                    <option value={4}>XL</option>
                                    <option value={5}>XXL</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
        return members;
    }

    isFormValid() {
        return true;
    }

    save() {
        let infos = new FormData();
        let data = {
            order: this.state.order
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/regate/order/edit/' + this.props.order, {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                this.addFlash("success", "Vos modifications ont bien été enregistrées.")
                this.setState({navigateTo: "/myregate/" + this.props.order})
                this.setState({shouldNavigate: true})
            } else {
                this.setState({error: data.error})
            }
        })
    }


    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1 style={{lineHeight: "44px", margin: "20px 0"}}>
                            Modifier mon inscription <br/>
                            <span style={{fontSize: "24px"}}>Régate {this.state.order.regate.antenne} - {moment(this.state.order.regate.startDate.date).format( "YYYY")}</span>
                        </h1>

                        {
                            this.state.order.boats.length > 0 &&
                            <div>
                                <h2>Information sur le bateau</h2>

                                {this.getBoatsForm()}
                            </div>
                        }

                        <div>
                            <h2>Participant{this.state.order.participations.length > 1 && "s"}</h2>

                            {this.getParticipantsForm()}
                        </div>

                        <button
                            className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                            style={{marginTop: "15px", padding: "10px 30px", alignSelf: "center"}}
                            disabled={!this.isFormValid()}
                            onClick={this.save.bind(this)}
                        >
                            Enregistrer
                        </button>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(EditRegate);
