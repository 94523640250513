import React, {Component} from "react"
import globalStyles from "../../../styles/globalStyles.module.css"
import styles from "./RegisterEvent.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchor,
    faCalendarDay,
    faClock, faGift,
    faMapMarkerAlt,
    faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import TemplateComponent, {api_url, mapStateToProps, stripe_pk} from "../../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import NoParcours from "../NoParcours";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js"
import SetupForm from "../../Utils/SetupForm/SetupForm";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;

class RegisterEvent extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            job: null,
            clientSecret: "",
            hasAlreadySetup: false,
            donation: 0,
        }
        this.load = {
            event: {
                start: false,
                end: false
            },
            setupIntent: {
                start: false,
                end: false
            }
        }
        this.donation = "";
        this.stripePromise = loadStripe(stripe_pk)
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadEvent()
    }

    loadEvent() {
        if(!this.load.event.start) {
            this.load.event.start = true
            let request = new Request(api_url + "/parcours/event/" + this.props.id, {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.event.end = true
                this.loadSetupIntent()
                this.setState({event: data.event, job: data.job});
            })
        }
    }

    loadSetupIntent(){
        if(!this.load.setupIntent.start) {
            this.load.setupIntent.start = true
            let request = new Request(api_url + "/pay/setup/create", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.setupIntent.end = true
                if(data.hasAlready && !this.props.user.user.cardLastFour) {
                    this.mustSecure = true
                    this.props.dispatch({type: "CHANGED"})
                }
                this.setState({clientSecret: data.clientSecret, hasAlreadySetup: data.hasAlready});
            })
        }
    }

    getTime(event) {
        let start = event.startDate.date;
        let end = event.endDate.date;
        if(moment(start).format("DD/MM/YYYY") === moment(end).format("DD/MM/YYYY")){
            return "De " + moment(start).format("HH:mm") + " à " + moment(end).format("HH:mm");
        } else {
            return "Du " + moment(start).format("DD/MM/YYYY HH:mm") + " au " + moment(end).format("DD/MM/YYYY HH:mm");
        }
    }

    registerEvent() {
        let data = new FormData();
        let infos = {
            donation: this.state.donation
        }
        console.log(this)
        console.log(infos)
        data.append("json", JSON.stringify(infos))
        let request = new Request(api_url + '/event/register/' + this.state.event.id, {method:'POST', body: data, credentials: "include"});
        this.fetchResponseOrLoginOrError(request, false).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                if(data.redirectUrl) {
                    this.setState({shouldNavigate: true, navigateTo: data.redirectUrl})
                }
                else {
                    this.addFlash("success", "Votre inscription a bien été prise en compte.")
                    setTimeout( () => {this.setState({shouldNavigate: true, navigateTo: "/myevent/" + data.id.toString()})}, 100)
                }
            }
        })
    }

    getFraisStripe() {
        return Math.ceil(this.state.event.payAmount*100*(1.5/98.5)+25*(100/98.5))/100
    }

    render() {

        if(!this.canRender()) {
            return this.noRender()
        }

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}
                {this.state.job ?
                    <div>
                        <div className={globalStyles.mainContainer}>
                            <h1 style={{marginBottom: "20px"}}>{this.state.event.name}</h1>

                            <div className={styles.card}>

                                <div style={{display: "flex", gap: "20px", alignItems: "center", flexDirection: window.innerWidth > 1100 ? "row" : "column"}}>

                                    <div style={{flex: 1}}>
                                        <div
                                            className={styles.contentContainer}
                                            style={{
                                                background: "#fafafa",
                                                boxShadow: "0 4px 10px 1px rgb(0 0 0 / 25%)",
                                                alignSelf: "stretch"
                                            }}
                                        >

                                            <h2 style={{marginBottom: "15px"}}>Récapitulatif</h2>

                                            <div className={styles.infosContainer}>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faCalendarDay}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{moment(this.state.event.startDate.date).format( "DD/MM/YYYY")}</b>
                                                    </div>
                                                </div>

                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={
                                                            this.state.event.type === SORTIE_MER ? faAnchor :
                                                                this.state.event.type === SOIREE_THEME ? faUsers :
                                                                    this.state.event.type === CONFERENCE ? faMicrophone :
                                                                        null
                                                        }/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.state.event.type === SORTIE_MER ? "Sortie en mer" :
                                                            this.state.event.type === SOIREE_THEME ? "Soirée à thème" :
                                                                this.state.event.type === CONFERENCE ? "Conférence" :
                                                                    null
                                                        }
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{this.state.event.place.name} - Antenne de {this.state.event.place.antenne}</b> <br/>
                                                        {this.state.event.place.streetAddress} <br/>
                                                        {this.state.event.place.streetAddressMore &&
                                                            <div>
                                                                {this.state.event.place.streetAddressMore} <br/>
                                                            </div>
                                                        }
                                                        {this.state.event.place.postCode} {this.state.event.place.city} <br/>
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faClock}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.getTime(this.state.event)}
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faUsers}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.state.event.capacity === 0 ? "Pas de limite de place" : this.state.event.capacity + " places"}
                                                        {!(this.state.event.capacity === 0) &&
                                                            <div className={styles.remainingPlace}
                                                                 style={{color: this.state.event.capacity - this.state.event.participants > 10 ? "var(--green)" : this.state.event.capacity - this.state.event.participants > 0 ? "var(--orange)" : "var(--red)"}}>
                                                                {this.state.event.capacity - this.state.event.participants > 0 ? "(" + (this.state.event.capacity - this.state.event.participants) + " places disponibles)" : "Plus de places disponibles"}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>


                                                <div className={styles.price}>
                                                    {this.state.event.payType === 2 ? "Prix : " + (this.state.event.payAmount + this.getFraisStripe()).toFixed(2) + "€" : "Gratuit"}
                                                    {this.state.event.payType === 1 &&
                                                        <div style={{fontSize: "12px"}}>
                                                            Une caution de {this.state.event.payAmount.toFixed(2)}€ vous sera demandée.
                                                        </div>
                                                    }
                                                    {this.state.event.payType === 2 &&
                                                        <div style={{fontSize: "12px"}}>
                                                            Dont {this.getFraisStripe().toFixed(2)} € de frais de paiement.
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <div style={{fontWeight: "bold", marginBottom: "3px"}}>Description</div>
                                                    <div style={{whiteSpace: "pre-wrap"}}>
                                                        {this.state.event.description}
                                                    </div>
                                                </div>


                                                { this.state.event.payType != 1 &&
                                                    <div className={styles.lineContainer}>
                                                        <div className={styles.icon}>
                                                            <FontAwesomeIcon icon={faGift}/>
                                                        </div>
                                                        <div className={styles.text}>
                                                            <div>Don à Guérir en mer : {(this.state.donation/100).toFixed(2)} €</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    { this.state.event.payType != 1 && this.state.event.eligible && !this.state.event.full &&
                                        <div style={{marginTop: 20}}>
                                            <h2 style={{marginBottom: "15px"}}>Faire un don</h2>
                                            <div>
                                                Vous pouvez ajouter au total à payer un don à l'association Guérir en mer.
                                            </div>
                                            <div style={{display: "flex", flexDirection:"row"}}>
                                                <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                                    <label className={globalStyles.label}>Don (€)</label>
                                                    <input type={"text"} name={"donation"} className={globalStyles.input}
                                                           value={this.donation}
                                                           onChange={(event) => {
                                                               this.donation = event.target.value
                                                               this.forceUpdate()
                                                           }}
                                                    />
                                                </div>
                                                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"flex-end", padding: 10}}>
                                                    <button onClick={() => {this.setState({donation: Math.max(0, Math.ceil(parseFloat(this.donation.replace(',', '.'))*100))})}} className={globalStyles.button} disabled={this.sending}>
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>

                                    { this.state.event.eligible && !this.state.event.full ?
                                    <div  className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : "20px 10px"}}>
                                        {this.state.event.payType === 1 ?
                                            <div className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : 0}}>
                                                {this.state.event.register ?
                                                    <div className={styles.buttonContainer}>
                                                        <div style={{
                                                            margin: "auto",
                                                            color: "var(--green)",
                                                            fontWeight: "bold"
                                                        }}>
                                                            Vous êtes déjà inscrit à cet événement.
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                            <div style={{margin: "10px"}}>
                                                                <div>
                                                                    En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                    Si vous ne venez pas, la caution de {this.state.event.payAmount.toFixed(2)}€ sera capturée.<br/>
                                                                    Si vous avez un empêchement, vous pourrez nous contacter.<br/>
                                                                    Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                                </div>
                                                                <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                    <SetupForm id={this.props.id} isEvent={true} isRegate={false}/>
                                                                </Elements>
                                                            </div>
                                                        }
                                                        { this.state.hasAlreadySetup &&
                                                            <div>
                                                                Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                                En cliquant sur le bouton <b>Valider</b>, vous vous inscrivez à l'événement et vous vous engagez à venir à l'événement.<br/>
                                                                Si vous ne venez pas, la caution de {this.state.event.payAmount.toFixed(2)}€ sera capturée.<br/>
                                                                Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                                Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                                <div className={styles.buttonContainer}>
                                                                        <button onClick={() => this.registerEvent()}
                                                                                className={globalStyles.button}>
                                                                            Valider l'inscription
                                                                        </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            :
                                            this.state.event.payType === 2 ?
                                                <div className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : 0}}>
                                                    {this.state.event.register ?
                                                        <div className={styles.buttonContainer}>
                                                            <div style={{
                                                                margin: "auto",
                                                                color: "var(--green)",
                                                                fontWeight: "bold"
                                                            }}>
                                                                Vous êtes déjà inscrit à cet événement.
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                                <div>
                                                                    <div>
                                                                        En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                        Si vous avez un empêchement, vous pourrez nous contacter.<br/>
                                                                        L'inscription à cet événement coûte {(this.state.event.payAmount + this.getFraisStripe()).toFixed(2)}€ (dont {this.getFraisStripe().toFixed(2)}€ de frais de paiement).<br/>
                                                                        { this.state.donation > 0 && <div>Vous avez choisi de faire un don de {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.</div>}
                                                                        Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                                    </div>
                                                                    <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                        <SetupForm id={this.props.id} isEvent={true} isRegate={false}/>
                                                                    </Elements>
                                                                </div>
                                                            }
                                                            { this.state.hasAlreadySetup &&
                                                                <div>
                                                                    Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                                    En cliquant sur le bouton <b>Valider et payer</b>,
                                                                    vous vous inscrivez à l'événement et payez {(this.state.event.payAmount + this.getFraisStripe()).toFixed(2)}€ (dont {this.getFraisStripe().toFixed(2)}€ de frais de paiement{this.state.donation > 0 && <span> et {(this.state.donation/100).toFixed(2)}€ de don à Guérir en mer</span>})<br/>
                                                                    Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                                    Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                                    <div className={styles.buttonContainer}>
                                                                        <button onClick={() => this.registerEvent()}
                                                                                className={globalStyles.button}>
                                                                            Valider et payer {(this.state.event.payAmount + this.getFraisStripe() + (this.state.donation/100)).toFixed(2)}€
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    </div>
                                                :
                                                this.state.donation === 0 ?
                                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                        <div className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : 0}}>
                                                            <div>
                                                                En cliquant sur le bouton <b>Valider</b>, vous vous
                                                                inscrivez à
                                                                l'événement et vous
                                                                vous engagez à venir à l'événement. Si jamais vous avez un
                                                                empêchement,
                                                                vous pouvez nous contacter.

                                                                Vous recevrez un e-mail de confirmation de votre
                                                                inscription.
                                                            </div>

                                                            <div className={styles.buttonContainer}>
                                                                {this.state.event.register ?
                                                                    <div style={{
                                                                        margin: "auto",
                                                                        color: "var(--green)",
                                                                        fontWeight: "bold"
                                                                    }}>
                                                                        Vous êtes déjà inscrit à cet événement.
                                                                    </div>
                                                                    :
                                                                    <button onClick={() => this.registerEvent()}
                                                                            className={globalStyles.button}>
                                                                        Valider l'inscription
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={styles.contentContainer} style={{padding: window.innerWidth > 800 ? "20px" : 0}}>
                                                        {this.state.event.register ?
                                                            <div className={styles.buttonContainer}>
                                                                <div style={{
                                                                    margin: "auto",
                                                                    color: "var(--green)",
                                                                    fontWeight: "bold"
                                                                }}>
                                                                    Vous êtes déjà inscrit à cet événement.
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                { this.load.setupIntent.end && !this.state.hasAlreadySetup &&
                                                                    <div>
                                                                        <div>
                                                                            En validant votre inscription à l'évènement, vous vous engagez à y venir.<br/>
                                                                            Si vous avez un empêchement, vous pourrez nous contacter.<br/>
                                                                            Vous avez choisi de faire un don de {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.<br/>
                                                                            Pour cela, vous devez enregistrer un moyen de paiement :<br/><br/>
                                                                        </div>
                                                                        <Elements stripe={this.stripePromise} options={{appearance: {theme: "stripe"}, clientSecret: this.state.clientSecret}}>
                                                                            <SetupForm id={this.props.id} isEvent={true} isRegate={false}/>
                                                                        </Elements>
                                                                    </div>
                                                                }
                                                                { this.state.hasAlreadySetup &&
                                                                    <div>
                                                                        Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                                                        En cliquant sur le bouton <b>Valider et donner</b>,
                                                                        vous vous inscrivez à l'événement et faites un don de  {(this.state.donation/100).toFixed(2)}€ à Guérir en mer.<br/>
                                                                        Si vous avez un empêchement, vous pourrez nous contacter.<br/><br/>

                                                                        Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                                                        <div className={styles.buttonContainer}>
                                                                            <button onClick={() => this.registerEvent()}
                                                                                    className={globalStyles.button}>
                                                                                Valider et donner {(this.state.donation/100).toFixed(2)}€
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                        }
                                    </div>
                                        :
                                    <div className={styles.contentContainer}>
                                        <div>
                                            En cliquant sur le bouton <b>Valider</b>, vous vous
                                            inscrivez sur la liste d'attente.<br/>
                                            S'il reste des places disponibles, vous serez contacté par
                                            e-mail.
                                        </div>

                                        <div className={styles.buttonContainer}>
                                            {this.state.event.register ?
                                            <div style={{
                                                margin: "auto",
                                                color: "var(--green)",
                                                fontWeight: "bold"
                                            }}>
                                                Vous êtes déjà inscrit à cet événement.
                                            </div>
                                                :
                                            <button onClick={() => this.registerEvent()}
                                                className={globalStyles.button}>
                                                Valider l'inscription sur la liste d'attente
                                            </button>
                                            }
                                        </div>
                                    </div>
                                    }

                                </div>

                            </div>

                        </div>
                    </div>
                    :
                    <NoParcours/>
                }

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(RegisterEvent);
