import React from 'react'
import { useParams } from 'react-router-dom';
import ConfirmAndPay from "./ConfirmAndPay";

function GetConfirmAndPayId () {
    let { id } = useParams();

    return(
        <ConfirmAndPay id={id}/>
    )
}

export default GetConfirmAndPayId;
