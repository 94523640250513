import React, {Component} from "react"
import globalStyles from "../../../styles/globalStyles.module.css"
import styles from "./Event.module.css"
import Header from "../../Header/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchor,
    faCalendarDay,
    faClock,
    faMapMarkerAlt,
    faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import boat from "../../../assets/boat.png"
import TemplateComponent, {api_url, mapStateToProps} from "../../../services/APITemplate";
import {connect} from "react-redux";
import Message from "../../Utils/Message/Message";
import moment from "moment";
import NoParcours from "../NoParcours";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;


class Event extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            event: null
        }
        this.load = {
            event: {
                start: false,
                end: false,
            }
        }
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadEvent()
    }

    loadEvent() {
        if(!this.load.event.start) {
            this.load.event.start = true
            let request = new Request(api_url + "/parcours/event/" + this.props.id, {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.event.end = true
                this.setState({event: data.event, job: data.job});
            })
        }
    }

    getTime(event) {
        let start = event.startDate.date;
        let end = event.endDate.date;
        if(moment(start).format("DD/MM/YYYY") === moment(end).format("DD/MM/YYYY")){
            return "De " + moment(start).format("HH:mm") + " à " + moment(end).format("HH:mm");
        } else {
            return "Du " + moment(start).format("DD/MM/YYYY HH:mm") + " au " + moment(end).format("DD/MM/YYYY HH:mm");
        }
    }

    render() {

        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}
                {this.state.job ?
                    <div>
                        <div className={globalStyles.mainContainer}>
                            <h1 style={{marginBottom: "20px"}}>{this.state.event.name}</h1>

                            <div className={styles.card}>

                                <div style={{display: "flex"}}>

                                    {this.state.event.image && window.innerWidth > 800 &&
                                        <img src={api_url + "/uploads/events/" + this.state.event.image} style={{flex: 1, width: "50%", objectFit: "cover"}}/>
                                    }

                                    <div className={styles.contentContainer}>

                                        <div className={styles.infosContainer}>
                                            <div className={styles.lineContainer}>
                                                <div className={styles.icon}>
                                                    <FontAwesomeIcon icon={faCalendarDay}/>
                                                </div>
                                                <div className={styles.text}>
                                                    <b>{moment(this.state.event.startDate.date).format( "DD/MM/YYYY")}</b>
                                                </div>
                                            </div>

                                            <div className={styles.lineContainer}>
                                                <div className={styles.icon}>
                                                    <FontAwesomeIcon icon={
                                                        this.state.event.type === SORTIE_MER ? faAnchor :
                                                            this.state.event.type === SOIREE_THEME ? faUsers :
                                                                this.state.event.type === CONFERENCE ? faMicrophone :
                                                                    null
                                                    }/>
                                                </div>
                                                <div className={styles.text}>
                                                    {this.state.event.type === SORTIE_MER ? "Sortie en mer" :
                                                        this.state.event.type === SOIREE_THEME ? "Soirée à thème" :
                                                            this.state.event.type === CONFERENCE ? "Conférence" :
                                                                null
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.lineContainer}>
                                                <div className={styles.icon}>
                                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                                </div>
                                                <div className={styles.text}>
                                                    <b>{this.state.event.place.name} - Antenne de {this.state.event.place.antenne}</b> <br/>
                                                    {this.state.event.place.streetAddress} <br/>
                                                    {this.state.event.place.streetAddressMore &&
                                                        <div>
                                                            {this.state.event.place.streetAddressMore} <br/>
                                                        </div>
                                                    }
                                                    {this.state.event.place.postCode} {this.state.event.place.city} <br/>
                                                </div>
                                            </div>
                                            <div className={styles.lineContainer}>
                                                <div className={styles.icon}>
                                                    <FontAwesomeIcon icon={faClock}/>
                                                </div>
                                                <div className={styles.text}>
                                                    {this.getTime(this.state.event)}
                                                </div>
                                            </div>
                                            <div className={styles.lineContainer}>
                                                <div className={styles.icon}>
                                                    <FontAwesomeIcon icon={faUsers}/>
                                                </div>
                                                <div className={styles.text}>
                                                    {this.state.event.capacity === 0 ? "Pas de limite de place" : this.state.event.capacity + " places"}
                                                    {!(this.state.event.capacity === 0) &&
                                                        <div className={styles.remainingPlace} style={{color: this.state.event.capacity - this.state.event.participants > 10 ? "var(--green)" : this.state.event.capacity - this.state.event.participants > 0 ? "var(--orange)" : "var(--red)"}}>
                                                            {this.state.event.capacity - this.state.event.participants > 0 ? "(" + (this.state.event.capacity - this.state.event.participants) + " places disponibles)" : "Plus de places disponibles"}
                                                        </div>
                                                    }
                                                </div>
                                            </div>


                                            <div className={styles.price}>
                                                {this.state.event.payType === 2 ? "Prix : " + this.state.event.payAmount.toFixed(2) + "€" : "Gratuit"}
                                                {this.state.event.payType === 1 &&
                                                    <div style={{fontSize: "12px"}}>
                                                        Une caution de {this.state.event.payAmount.toFixed(2)} € vous sera demandée.
                                                    </div>
                                                }
                                            </div>

                                            <div>
                                                <div style={{fontWeight: "bold", marginBottom: "3px"}}>Description</div>
                                                <div style={{whiteSpace: "pre-wrap"}}>
                                                    {this.state.event.description}
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.event.register ?
                                            <div style={{margin: "20px auto", textAlign: "center", color: "var(--green)", fontWeight: "bold"}}>
                                                Vous êtes déjà inscrit
                                            </div>
                                            :
                                            <div style={{marginTop: "20px"}}>
                                                {this.state.event.full &&
                                                    <Message
                                                        type={"danger"}
                                                        title={"Il n’y a plus de place disponible !"}
                                                        text={"Vous pouvez tout de même vous inscrire sur la liste d’attente. Nous vous contacterons si des places se libèrent !"}
                                                        button={{
                                                            link: "/event/register/" + this.state.event.id,
                                                            text: "M'inscrire sur la liste d'attente"
                                                        }}
                                                    />
                                                }
                                                {!this.state.event.full && this.state.event.eligible &&
                                                    <Message
                                                        type={"success"}
                                                        title={"Vous êtes éligibles pour cet événement."}
                                                        text={"Vous pouvez vous inscrire à cet événement."}
                                                        button={{
                                                            link: "/event/register/" + this.state.event.id,
                                                            text: "S'inscrire"
                                                        }}
                                                    />
                                                }
                                                {!this.state.event.full && !this.state.event.eligible && this.state.event.needTest &&
                                                    <Message
                                                        type={"warning"}
                                                        title={"Cette sortie est réservée aux soignants en situation de burnout."}
                                                        text={"Pour pouvoir vous y inscrire, vous devez réaliser le test de Maslach pour vérifier votre éligibilité."}
                                                        button={{
                                                            link: "/maslach/" + this.state.event.id,
                                                            text: "Faire le test"
                                                        }}
                                                    />
                                                }
                                                {!this.state.event.full && !this.state.event.eligible && !this.state.event.needTest &&
                                                    <Message
                                                        type={"danger"}
                                                        title={"Cette sortie est réservée aux soignants en situation de burnout."}
                                                        text={"Suite aux résultats de votre test, il semblerait que vous ne soyez pas en situation de burnout. Vous pouvez tout de même vous inscrire sur la liste d’attente. Nous vous contacterons s’il reste des places disponibles !"}
                                                        button={{
                                                            link: "/event/register/" + this.state.event.id,
                                                            text: "M'inscrire sur la liste d'attente"
                                                        }}
                                                    />
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    :
                    <NoParcours/>
                }
                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Event);
