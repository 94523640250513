import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import Empty from "../Utils/Empty/Empty";
import {connect} from "react-redux";


class Logout extends TemplateComponent {

    doesMustLog() {
        this.mustLog = 1
        this.load = {
            logout: {
                start: false,
                end: false,
            }
        }
    }

    loadData() {
        if(!this.load.logout.start) {
            this.load.logout.start = true
            let request = new Request(api_url + '/logout', {credentials: 'include'})
            fetch(request)
            this.load.logout.end = true
            this.props.dispatch({
                type: "LOGOUT"
            })
            this.setState({shouldNavigate: true, navigateTo: "/login"})
        }
    }

    render() {
        if(!this.canRender()) {
            return this.noRender()
        }
        return <Empty/>
    }
}

export default connect(mapStateToProps)(Logout);