import React from 'react'
import { useParams } from 'react-router-dom';
import EditRegate from "./EditRegate";

function GetEditRegateId () {
    let { id } = useParams();

    return(
        <EditRegate order={id}/>
    )
}

export default GetEditRegateId;
