import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./MyEvents.module.css"
import CardEvent from "./CardEvent";
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import CardRegate from "./CardRegate";

class MyEvents extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            participations: [],
            job: null
        }
        this.load = {
            event : {
                start: false,
                end: false
            }
        }
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadEvents()
    }

    loadEvents() {
        if(!this.load.event.start) {
            this.load.event.start = true
            let request = new Request(api_url + "/events", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.event.end = true
                this.setState({participations: data});
            })
        }
    }

    getTime(event) {
        let start = event.startDate.date;
        let end = event.endDate.date;
        if(moment(start).format("DD/MM/YYYY") === moment(end).format("DD/MM/YYYY")){
            return "De " + moment(start).format("HH:mm") + " à " + moment(end).format("HH:mm");
        } else {
            return "Du " + moment(start).format("DD/MM/YYYY HH:mm") + " au " + moment(end).format("DD/MM/YYYY HH:mm");
        }
    }

    getEvents() {
        let content = [];
        let len = this.state.participations.sorties.length;
        for(let i = 0; i < len; i++){
            content.push(
                <CardEvent
                    date={moment(this.state.participations.sorties[i].event.startDate.date).format( "DD/MM/YYYY")}
                    titre={this.state.participations.sorties[i].event.name}
                    type={this.state.participations.sorties[i].event.type}
                    place={this.state.participations.sorties[i].event.place.name + " - " + this.state.participations.sorties[i].event.place.antenne}
                    time={this.getTime(this.state.participations.sorties[i].event)}
                    state={this.state.participations.sorties[i].state}
                    link={"/myevent/" + this.state.participations.sorties[i].id}
                />
            )
        }
        return content
    }

    getRegates() {
        let content = [];
        let len = this.state.participations.regates.length;
        for(let i = 0; i < len; i++){
            content.push(
                <CardRegate
                    startDate={this.state.participations.regates[i].regate.startDate.date}
                    endDate={this.state.participations.regates[i].regate.endDate.date}
                    antenne={this.state.participations.regates[i].regate.antenne}
                    state={this.state.participations.regates[i].state}
                    link={"/myregate/" + this.state.participations.regates[i].id}
                />
            )
        }
        return content
    }

    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Mes inscriptions</h1>

                        <h2>Événements Parcours GEM</h2>
                        <div className={styles.container}>
                            {this.getEvents()}
                        </div>

                        <h2 style={{marginTop: "40px"}}>Événement annuel</h2>
                        <div className={styles.container}>
                            {this.getRegates()}
                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(MyEvents);
