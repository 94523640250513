import styles from './Empty.module.css';
import React, {Component} from "react"
import logo from "../../../assets/logos/logo_green.png";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

class NotFound extends Component {

    render () {

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                <Header user={{userConnected: false}}/>
                <div className={styles.div_100}>
                    <h1>Oups, cette page n'existe pas.</h1>
                    <img src={logo} alt={"Logo"} className={styles.error_logo}/>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default NotFound;
