import React from 'react'
import { useParams } from 'react-router-dom';
import RegisterEvent from "./RegisterEvent";

function GetRegisterEventId () {
    let { id } = useParams();

    return(
        <RegisterEvent id={id}/>
    )
}

export default GetRegisterEventId;
