import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Profile.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit} from "@fortawesome/free-solid-svg-icons";


class Profile extends TemplateComponent {


    doesMustLog() {
        this.mustLog = 1
    }

    deletePayment() {
        let request = new Request(api_url + '/payment/delete', {method:'GET', credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.deleted) {
                this.props.dispatch({
                    type: "CHANGED"
                })
                this.mustSecure = true
                this.addFlash("success", "Votre moyen de paiement a bien été supprimé.")
            }
        })
    }


    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }

        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Mon profil</h1>

                        <div className={styles.container}>
                            <div>
                                <span style={{textDecoration: "underline", fontWeight: 600}}>Informations</span> <a href={"/profile/edit"}>
                                    <FontAwesomeIcon icon={faEdit} style={{color: "var(--green)", cursor: "pointer"}}/>
                                </a>
                            </div>

                            <div><b>Nom :</b> {this.props.user.user.lastName}</div>
                            <div><b>Prénom :</b> {this.props.user.user.firstName}</div>
                            <div><b>E-mail :</b> {this.props.user.user.email}</div>
                            <div><b>Téléphone :</b> {this.props.user.user.phone}</div>
                            <div><b>Profession :</b> {this.props.user.user.job}</div>
                            <a
                                className={globalStyles.buttonRound}
                                style={{fontSize: "14px", alignSelf: "flex-start"}}
                                href={"/password/edit"}
                            >
                                Modifier le mot de passe
                            </a>


                            <div style={{marginTop: "30px"}}><b>Moyen de paiement enregistré :</b> {this.props.user.user.cardLastFour ? "**** **** **** " + this.props.user.user.cardLastFour : "Aucun"}</div>
                            {this.props.user.user.cardLastFour &&
                                <button
                                    className={globalStyles.buttonRound}
                                    style={{fontSize: "14px", alignSelf: "flex-start"}}
                                    onClick={this.deletePayment.bind(this)}
                                >
                                    Supprimer le moyen de paiement
                                </button>
                            }

                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Profile);
