import React from "react"
import globalStyles from "../../../styles/globalStyles.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import styles from "./MyRegate.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {

    faCalendarDay,

    faMapMarkerAlt,

} from "@fortawesome/free-solid-svg-icons";

const BASKET = 0;
const VALIDATE = 1;

class MyRegate extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            participation: null,
            code: "",
            error: ""
        };
        this.load = {
            order : {
                start: false,
                end: false
            },
        }
        this.loadNb = 0;
        this.displayLoading = false;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadOrder()
    }

    loadOrder() {
        if(!this.load.order.start) {
            this.load.order.start = true
            let request = new Request(api_url + "/myregate/" + this.props.order, {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.order.end = true
                this.displayLoading = false
                if(data.success){
                    this.setState({order: data.order});
                    if(data.order.state === BASKET && this.loadNb < 4) {
                        this.displayLoading = true;
                        this.loadNb++;
                        setTimeout(() => {
                            this.load.event.order = false
                            this.loadOrder();
                        }, 2000);
                    }
                    if(this.loadNb >= 4) {
                        this.displayLoading = false
                    }
                } else {
                    this.setState({shouldNavigate: true, navigateTo: "/myevents"});
                }
            })
        }
    }

    addNavigateWithCode() {
        let infos = new FormData();
        let data = {
            code: this.state.code
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/regate/addNavigateWith/' + this.props.order, {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                this.addFlash("success", "Nous avons bien pris en compte votre demande.")
                window.location.reload()
            } else {
                this.setState({error: data.error})
            }
        })
    }

    getValueOrNull(value) {
        if(value === null || value === "" || value === undefined){
            return <span style={{color: "red"}}>Non renseigné</span>;
        }
        return value;
    }

    getParticipants() {
        let content = [];
        let len = this.state.order.participations.length;
        for(let i = 0; i < len; i++){
            content.push(
                <div className={styles.card} style={{margin: "5px 0"}}>
                    {len > 1 && <b style={{textDecoration: "underline"}}>Membre {i + 1}</b>}
                    <div><b>Nom : </b>{this.getValueOrNull(this.state.order.participations[i].lastName)}</div>
                    <div><b>Prénom : </b>{this.getValueOrNull(this.state.order.participations[i].firstName)}</div>
                    <div><b>Licence FFV : </b>{this.getValueOrNull(this.state.order.participations[i].licence)}</div>
                    <div><b>Genre : </b>{this.getValueOrNull(this.state.order.participations[i].genderString)}</div>
                    <div><b>Taille : </b>{this.getValueOrNull(this.state.order.participations[i].sizeString)}</div>
                    <div><b>Niveau : </b>{this.getValueOrNull(this.state.order.participations[i].sailingLevelString)}</div>
                    <div><b>Participe à : </b>{this.state.order.participations[i].participateIn}</div>
                </div>
            )
        }
        return content;
    }

    getBoats() {
        let content = [];
        let len = this.state.order.boats.length;
        for(let i = 0; i < len; i++){
            content.push(
                <div className={styles.card}>
                    {len > 1 && <div style={{margin: "5px 0"}}><b>Participant {i + 1}</b></div>}
                    <div><b>Nom : </b>{this.state.order.boats[i].name}</div>
                    <div><b>Modèle : </b>{this.state.order.boats[i].model}</div>
                    <div><b>Longueur : </b>{this.state.order.boats[i].length} m</div>
                    <div><b>Nombre de places : </b>{this.state.order.boats[i].nbPlaces}</div>
                    <div><b>Places ouvertes : </b>{this.state.order.availablePlaces}</div>
                    <div><b>Assurance : </b><a href={api_url + "/uploads/boats/" + this.state.order.boats[i].insuranceFile}>Voir le fichier</a></div>
                    <div><b>Certificat de jauge : </b> {this.state.order.boats[i].jaugeFile ? <a href={api_url + "/uploads/boats/" + this.state.order.boats[i].jaugeFile}>Voir le fichier</a> : "Non renseigné"} </div>
                </div>
            )
        }
        return content;
    }

    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Régate {this.state.order.regate.antenne} - {moment(this.state.order.regate.startDate.date).format( "YYYY")}</h1>

                        <div style={{display: "flex", flexDirection: window.innerWidth > 1000 ? "row" : "column"}}>
                            <div className={styles.infosContainer}>
                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={faCalendarDay}/>
                                    </div>
                                    <div className={styles.text}>
                                        <b>{moment(this.state.order.regate.startDate.date).format( "DD/MM/YYYY")} - {moment(this.state.order.regate.endDate.date).format( "DD/MM/YYYY")}</b>
                                    </div>
                                </div>

                                <div className={styles.lineContainer}>
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <div className={styles.text}>
                                        {this.state.order.regate.antenne}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.persoContainer}>
                                <div className={styles.lineContainer}>

                                    {this.state.order.state === VALIDATE ?
                                        <div className={styles.labelGreen}>
                                            Votre inscription est validée. <br/>
                                        </div>
                                        :
                                        this.state.order.state === BASKET ?
                                            <div className={styles.labelRed}>
                                                <div style={{flex:1}}>En attente de paiement</div>
                                                { this.displayLoading && <div style={{flex:1, alignItems: "center", justifyContent: "center"}}><div className={styles.overLoading}><div className={styles.loading}/></div></div>}
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={styles.infosContainer} style={{marginTop: "40px"}}>
                            <h2>Détails de l'inscription</h2>

                            {
                                this.state.order.boats.length > 0 &&
                                    <div>
                                        <div style={{textDecoration: "underline", fontWeight: 600, marginBottom: "5px"}}>Bateau{this.state.order.boats.length > 1 && "x"}</div>
                                        <div className={styles.participants}>
                                            {this.getBoats()}
                                        </div>
                                    </div>
                            }

                            <div style={{textDecoration: "underline", fontWeight: 600, marginBottom: "5px", marginTop: "10px"}}>
                                Participant{this.state.order.participations.length > 1 && "s"}
                            </div>

                            {this.state.order.participations.length === 1 &&
                                <div><b>Code :</b> {this.state.order.participations[0].code}</div>
                            }

                            <div className={styles.grid}>
                                {this.getParticipants()}
                            </div>

                            <a
                                className={globalStyles.buttonRound}
                                style={{fontSize: "14px", alignSelf: "flex-start"}}
                                href={"/edit/regate/" + this.props.order}
                            >
                                Modifier mon inscription
                            </a>

                            {this.state.order.participations.length === 1 &&
                                <div>
                                    <h2>
                                        Partenaires de bateau
                                    </h2>

                                    <div className={styles.grid}>

                                        <div className={styles.card}>
                                            <div style={{textDecoration: "underline", fontWeight: 600, marginBottom: "5px", marginTop: "10px"}}>
                                                Partenaires
                                            </div>

                                            <b>Demande en attente :</b> {this.state.order.participations[0].navigateWithAsk.join(", ")}
                                            <b>Partenaires :</b> {this.state.order.participations[0].navigateWith.join(", ")}
                                        </div>

                                        <div className={styles.card}>

                                            <div style={{textDecoration: "underline", fontWeight: 600, marginBottom: "5px", marginTop: "10px"}}>
                                                Ajouter un partenaire
                                            </div>

                                            <div style={{fontSize: "12px", color: "red"}}>
                                                {this.state.error}
                                            </div>

                                            <div className={globalStyles.inputContainer}>
                                                <label className={globalStyles.label}>Code (8 caractères)</label>
                                                <input type={"text"} name={"length"} className={globalStyles.input}
                                                       value={this.state.code}
                                                       onChange={(event) => {
                                                           this.setState({code: event.target.value})
                                                       }}
                                                />
                                            </div>

                                            <button
                                                className={globalStyles.buttonRound}
                                                style={{alignSelf: "center"}}
                                                onClick={() => this.addNavigateWithCode()}
                                            >
                                                Ajouter
                                            </button>

                                        </div>

                                    </div>


                                </div>
                            }

                        </div>

                    </div>
                </div>

                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(MyRegate);
