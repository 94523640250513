import globalStyles from "../../styles/globalStyles.module.css";
import React, {Component} from "react";

class NoParcours extends Component {
    render() {

        return(
            <div className={globalStyles.mainContainer} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "calc(100vh - 80px)"}}>
                <h1>Le Parcours</h1>

                <p style={{fontSize: "20px", textAlign: "center", marginTop: "5px"}}>
                    Sorties réservées aux professionnels de santé. Si vous pensez que vous pouvez être bénéficiaire,
                    <a href="mailto:contact@guerirenmer.com">contactez-nous</a>.
                </p>
            </div>
        )
    }
}

export default NoParcours
