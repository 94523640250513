import React from 'react'
import { useParams } from 'react-router-dom';
import Event from "./Event";

function GetEventId () {
    let { id } = useParams();

    return(
        <Event id={id}/>
    )
}

export default GetEventId;
