import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Header.module.css"
import logo from "../../assets/logos/logo_horizontal.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }

    render () {
        if(window.innerWidth > 1080){
            return (
                <div>
                    <navbar className={styles.nav}>
                        <a href={"/"} style={{height: "100%"}}>
                            <img src={logo} style={{height: "90%"}}/>
                        </a>

                    <div className={styles.navLinkContainer}>
                        <a className={styles.navLink} href={"/parcours"}>
                            Parcours GEM
                        </a>
                        <a className={styles.navLink} href={"/regate"}>
                            L'Événement Annuel
                        </a>
                        <a className={styles.navLink} href={"/myevents"}>
                            Mes Inscriptions
                        </a>
                    </div>

                    <div className={styles.buttonContainer}>
                        {this.props.user.userConnected ?
                            <button className={globalStyles.buttonRound} style={{paddingTop: "7px", paddingBottom: "7px"}} onClick={() => {this.setState({menu: !this.state.menu})}}>
                                <span className={styles.buttonText}>Bonjour {this.props.user.user.firstName}</span>
                            </button>
                        :
                            <a href={"/login"}>
                                <button className={globalStyles.buttonRound} style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                    <span className={styles.buttonText}>Se connecter</span>
                                </button>
                            </a>
                        }
                    </div>
                </navbar>

                    {this.props.user.userConnected && this.state.menu &&
                        <div className={styles.dropdownMenuConnected}>

                            <div className={styles.dropdownMenuItem}>
                                <a className={styles.dropdownLink} href="/profile">
                                    Mon compte
                                </a>
                            </div>

                            <div className={styles.dropdownMenuItem}>
                                <a className={styles.dropdownLink} href="/logout">
                                    Se déconnecter
                                </a>
                            </div>
                        </div>
                    }

                </div>
            );
        } else {
            return(
                <navbar className={styles.nav}>
                    <img src={logo} style={{height: "90%"}}/>

                    <div className={styles.buttonContainer}>
                        <button
                            onClick={() => {
                                if(this.state.menu){
                                    document.body.style.overflow = "auto"
                                } else {
                                    document.body.style.overflow = "hidden"
                                }
                                this.setState({menu: !this.state.menu})
                            }}
                            style={{background: "transparent", border: "none", cursor: "pointer"}}
                        >
                            <FontAwesomeIcon icon={this.state.menu ? faTimes : faBars} color={"white"} style={{fontSize: "1.3rem"}}/>
                        </button>
                    </div>

                    {this.state.menu &&
                        <div className={styles.overlayNavbar}>
                            <div className={styles.navLinkContainer}>
                                <a className={styles.navLink} href={"/parcours"}>
                                    Parcours GEM
                                </a>
                                <a className={styles.navLink} href={"/regate"}>
                                    L'Événement Annuel
                                </a>
                                <a className={styles.navLink} href={"/myevents"}>
                                    Mes Inscriptions
                                </a>
                                <a className={styles.navLink} href={"/profile"}>
                                    Mon profil
                                </a>
                                <a className={styles.navLink} href={"/logout"}>
                                    Déconnexion
                                </a>

                            </div>


                        </div>
                    }
                </navbar>
            )

        }

    }
}

export default Header;
