import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Register.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Message from "../Utils/Message/Message";
import ReactCodeInput from "react-code-input";

class ValidateEmail extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email.toLowerCase(),
            code: "",
            error: null
        }
    }

    doesMustLog() {
        this.mustLog = -1
    }

    loadData() {

    }

    validate() {
        let infos = new FormData();
        let data = {
            email: this.state.email.toLowerCase(),
            code: this.state.code
        }

        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/validateEmail', {method:'POST', body: infos});
        this.fetchResponseOrLoginOrError(request, false).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {
                this.props.dispatch({
                    type: "LOGIN",
                    user: data
                })
                this.setState({shouldNavigate: true, navigateTo: '/login'})
            } else {
                this.setState({error: data.error})
            }
        })

    }

    isFormValid() {
        return this.state.code.length === 8;
    }

    render() {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div>
                {this.flashtoDisplay}
                <div className={styles.container}>
                    <div className={globalStyles.leftPart}/>

                    <div className={globalStyles.rightPart}>
                        <div className={styles.content}>
                            <h1 className={styles.title}>Inscrivez-vous</h1>

                            <div>
                                <div style={{fontSize: "18px", marginTop: "10px"}}>
                                    <b>Il vous reste à confirmer votre adresse e-mail</b>
                                </div>

                                <p>
                                    Pour pouvoir continuer, veuillez confirmer votre adresse {this.state.email},
                                    en rentrant le code que vous avez reçu par e-mail.
                                </p>
                            </div>

                            { this.state.error &&
                                <Message
                                    type={"danger"}
                                    title={"Oups, quelque chose s'est mal passé."}
                                    text={this.state.error}
                                />
                            }

                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "50px 0"}}>
                                <ReactCodeInput
                                    type='text'
                                    fields={8}
                                    value={this.state.code}
                                    onChange={(value) => {this.setState({code: value})}}
                                    inputStyle={{
                                        fontFamily: 'monospace',
                                        margin:  '4px',
                                        MozAppearance: 'textfield',
                                        borderRadius: '6px',
                                        fontSize: '40px',
                                        paddingLeft: '10px',
                                        color: 'black',
                                        border: '1px solid lightgrey',
                                        height: "50px",
                                        width: "30px"
                                    }}
                                />
                            </div>



                            <button
                                className={this.isFormValid() ? globalStyles.button : globalStyles.disabledButton}
                                style={{marginTop: "15px"}}
                                disabled={!this.isFormValid()}
                                onClick={this.validate.bind(this)}
                            >
                                Valider
                            </button>

                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ValidateEmail);
